import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import '../styles/Comments.css';
import { formatTimestamp } from '../Utilities/dateUtils'; 

const Comments = ({ jobData, propertyDetails }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [userRole, setUserRole] = useState('');

  const [landlordName, setLandlordName] = useState('Landlord');
  const [contractorName] = useState('Contractor');

  const sender =
    userRole === 'Admin'
      ? 'Admin'
      : userRole === 'Landlord'
      ? landlordName
      : userRole === 'Contractor'
      ? contractorName
      : 'Unknown'; // Fallback for undefined roles

  useEffect(() => {
    const fetchUserRole = async () => {
      const user = auth.currentUser;
      const userDoc = await getDoc(doc(db, 'users', user.email));
      if (userDoc.exists()) {
        setUserRole(userDoc.data().role);
      }
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    if (propertyDetails?.user?.firstName && propertyDetails?.user?.lastName) {
      setLandlordName(`${propertyDetails.user.firstName} ${propertyDetails.user.lastName}`);
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (!jobData.jobId || typeof jobData.jobId !== 'string' || jobData.jobId.trim() === '') {
      console.error('Invalid jobId:', jobData.jobId);
      return;
    }

    const fetchComments = async () => {
      try {
        const commentsRef = doc(db, 'comments', jobData.jobId);
        const commentsSnap = await getDoc(commentsRef);

        if (commentsSnap.exists()) {
          console.log('Fetched comments document:', commentsSnap.data());
          setMessages(commentsSnap.data().comments || []);
        } else {
          console.log(`No comments found for jobId: ${jobData.jobId}`);
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, [jobData.jobId]);

  const handleSendMessage = async () => {
    if (!jobData.jobId || typeof jobData.jobId !== 'string' || jobData.jobId.trim() === '') {
      console.error('Invalid jobId:', jobData.jobId);
      return;
    }

    if (!newMessage.trim()) return;

    const messageData = {
      sender,
      recipient: selectedTarget || null, // Dynamically include the selected recipient
      text: newMessage.trim(),
      timestamp: serverTimestamp(),
    };
    

    try {
      const jobId = jobData.jobId
      const commentsRef = doc(db, 'comments', jobData.jobId);
      const commentsSnap = await getDoc(commentsRef);
      if (commentsSnap.exists()) {
        // Document exists, update it
        await updateDoc(commentsRef, {
          comments: arrayUnion({
            ...messageData,
            timestamp: new Date(), // Use local timestamp for optimistic UI update
          }),
        });
        console.log('Message added to existing document:', messageData);
      } else {
        // Document does not exist, create it
        await setDoc(commentsRef, {
          jobId,
          comments: [
            {
              ...messageData,
              timestamp: new Date(), // Use local timestamp for optimistic UI update
            },
          ],
        });
        console.log('Created new document with message:', messageData);
      }

      // Update local state optimistically
      setMessages((prevMessages) => [
        ...prevMessages,
        { ...messageData, timestamp: new Date() }, // Optimistic local timestamp
      ]);
      setNewMessage('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <div className="comments-main-container">
      {/* Left Container */}
      <div className="comments-left-container">
        <div className="comments-search-container">
          <div>Comments for {jobData.jobId}</div>
        </div>

        <div className="comments-chat-list">
  {[
    { name: 'Admin', description: 'CMH Staff', role: 'admin' },
    {
      name: `${propertyDetails?.user?.firstName || 'Unknown'} ${
        propertyDetails?.user?.lastName || ''
      }`,
      description: 'Landlord',
      role: 'landlord',
    },
    ...(jobData?.contractorName
      ? [{ name: jobData.contractorName, description: 'Contractor', role: 'contractor' }]
      : []),
  ]
  .map((user) => ({
    ...user,
    isDisabled: user.role === userRole.toLowerCase(),
  }))
    .map(({ name, description, role }) => (
      <div
        key={role}
        className={`comments-chat-box ${selectedTarget === role ? 'selected' : ''}`}
        onClick={() =>
          role !== userRole.toLowerCase() && setSelectedTarget(selectedTarget === role ? null : role)
        }
      >
        <h4 className="role">{name}</h4>
        <p className="role-description">{description}</p>
      </div>
    ))}

{/* Info Panel */}
<div className="info-panel">
  <p>
    {selectedTarget
      ? "Click on the highlighted name to unselect"
      : "Click on a name above to direct a message to"}
  </p>
</div>
      </div>

      </div>

      {/* Right Container */}
      <div className="comments-right-container">
        <div className="comments-chat-container">

{messages.map((message, index) => {
  return (
<div
  key={index}
  className={`comments-message-box ${
    message.sender === sender ? 'comments-my-message' : 'comments-friend-message'
  }`}
>
<p>
  <strong>
    {message.sender}
    {message.recipient && (
      <span className="recipient-name">
        {' '}
        to {message.recipient.charAt(0).toUpperCase() + message.recipient.slice(1)}
      </span>
    )}
  </strong>
  <br />
  {message.text}
  <br />
  <span className="timestamp">{formatTimestamp(message.timestamp)}</span>
</p>

</div>
  );
})}

          <div className="comments-chatbox-input">
            <input
              type="text"
              placeholder={`Send a message to ${
                selectedTarget ? (selectedTarget === 'landlord' ? landlordName : selectedTarget) : 'everyone'
              }`}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="chatbox-input"
            />
            <button onClick={handleSendMessage} className="chatbox-send-button">Send</button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Comments;
