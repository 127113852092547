import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase'; // Firebase auth instance
import { getDoc, doc } from 'firebase/firestore'; // For fetching role from Firestore
import { db } from '../../firebase'; // Firestore instance
import '../../styles/Header.css';

const Header = () => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        console.log('Header: User signed in:', firebaseUser.email);
        setUser(firebaseUser);

        try {
          // Fetch user role by email
          const role = await fetchUserRole(firebaseUser.email);
          setUserRole(role);
        } catch (error) {
          console.error('Header: Error fetching user role:', error);
        }
      } else {
        console.log('Header: No user signed in');
        setUser(null);
        setUserRole(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  // Fetch the user’s role from Firestore by email
  const fetchUserRole = async (email) => {
    try {
      const docRef = doc(db, 'users', email); // Ensure email is used as Firestore document ID
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Header: Role fetched:', docSnap.data().role);
        return docSnap.data().role;
      } else {
        console.warn('Header: No document found for email:', email);
        return 'Unknown';
      }
    } catch (error) {
      console.error('Header: Error fetching role for email:', error);
      return 'Unknown';
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('Header: User signed out');
      setUser(null);
      setUserRole(null);
      window.location.href = '/';
    } catch (error) {
      console.error('Header: Error during logout:', error);
    }
  };

  return (
<div className="header-panel">
  <div className="header-left">
    <a href="#0" aria-label="MMG logo">
      <img src="mmg-logo.png" alt="Logo" className="logo" />
    </a>
  </div>

  <div className="header-center">
    <span className="header-content-panel-title">
      <span className="header-title-text">Complete Maintenance Hub</span>
      <span className="header-slogan">Property Maintenance made easy</span>
    </span>
  </div>

  <div className="header-right">
    {user ? (
      <div className="user-info">
        <span className="user-email">{user.email}</span>
        <span className="user-role">{userRole || 'Fetching role...'}</span>
        <button className="logout-button" onClick={handleLogout}>
          Log Out
        </button>
      </div>
    ) : (
      <span className="not-signed-in">Not signed in</span>
    )}
  </div>
</div>

  );
};

export default Header;
