import { db } from '../firebase';
import { collection, query, where, orderBy, getDocs, doc, setDoc, serverTimestamp, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';

export const STATUS_MAPPING = {
  "0": { label: 'Awaiting Price', description: 'The job is awaiting a quote to be submitted.' },
  "1": { label: 'Awaiting Approval', description: 'The quote has been submitted and is awaiting your approval.' },
  "2": { label: 'Approved', description: 'The quote has been approved, and the job is queued for execution.' },
  "-1": { label: 'Rejected', description: 'The quote has been rejected by the landlord.' },
  "default": { label: 'Unknown', description: 'The job status is not recognized.' },
};

export const SUBSCRIPTION_MAPPING = {
  "0": {labelShort: 'Platinum', labelLong: 'Platinum Member', description: 'In house tradesperson', cost: 0, releaseAfter: 0},
  "1": {labelShort: 'Gold', labelLong: 'Gold Member', description: 'Early access to jobs.', cost: 30, releaseAfter: 30},
  "2": {labelShort: 'Silver', labelLong: 'Silver Member', description: 'Priviledged access to jobs.', cost: 20, releaseAfter: 60},
  "3": {labelShort: 'Bronze', labelLong: 'Bronze Member', description: 'Standard access to jobs.', cost: 10, releaseAfter: 90},
};

/**
 * Fetches properties submitted by an agent and includes inviteAcceptedAt from the associated landlord's user document.
 * @param {string} agentEmail - The email address of the agent.
 * @returns {Promise<Array>} - An array of properties associated with the agent, enriched with inviteAcceptedAt.
 */
export const AgentsPropertiesSubmitted = async (agentEmail) => {
  try {
    const propertiesRef = collection(db, 'properties'); // Reference to the properties collection

    // Query properties where agentId matches the given agentEmail
    const propertiesQuery = query(propertiesRef, where('agentId', '==', agentEmail));
    const querySnapshot = await getDocs(propertiesQuery);

    const properties = await Promise.all(
      querySnapshot.docs.map(async (propertyDoc) => {
        const propertyData = propertyDoc.data();
        const landlordEmail = propertyData.landlordsEmail;

        if (landlordEmail) {
          try {
            // Fetch the corresponding landlord user document
            const landlordDocRef = doc(db, 'users', landlordEmail);
            const landlordDocSnapshot = await getDoc(landlordDocRef);

            return {
              id: propertyDoc.id,
              ...propertyData,
              inviteAcceptedAt: landlordDocSnapshot.exists() ? landlordDocSnapshot.data().inviteAcceptedAt : null,
            };
          } catch (landlordError) {
            console.error(`Error fetching landlord data for ${landlordEmail}:`, landlordError);
            return {
              id: propertyDoc.id,
              ...propertyData,
              inviteAcceptedAt: null,
            };
          }
        } else {
          return {
            id: propertyDoc.id,
            ...propertyData,
            inviteAcceptedAt: null,
          };
        }
      })
    );

    return properties;
  } catch (error) {
    console.error('Error fetching properties submitted by agent:', error);
    alert('Error fetching properties submitted by agent: ' + error);
    throw error;
  }
};

/**
 * Fetch jobs for a specific property and status.
 * @param {string} propertyId - The ID of the property.
 * @param {number} status - The status of the jobs to fetch.
 * @returns {Promise<Array>} - Array of jobs matching the criteria.
 */
export const fetchJobsByPropertyAndStatus = async (propertyId, status) => {
  if (!propertyId || typeof propertyId !== 'string') {
    throw new Error('Invalid propertyId: Must be a non-empty string.');
  }

  if (typeof status !== 'number') {
    throw new Error('Invalid status: Must be a number.');
  }

  try {
    console.log(`Fetching jobs for propertyId: ${propertyId} with status: ${status}`);
    const jobsRef = collection(db, 'jobs'); // Reference to the jobs collection
    const jobsQuery = query(
      jobsRef,
      where('propertyId', '==', propertyId),
      where('status', '==', status),
      orderBy('createdAt') // Ensure `createdAt` is indexed
    );

    const querySnapshot = await getDocs(jobsQuery);

    if (querySnapshot.empty) {
      console.log('No jobs found matching the criteria.');
      return [];
    }

    const jobs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log('Fetched jobs:', jobs);
    return jobs;
  } catch (error) {
    console.error('Error fetching jobs by property and status:', error);
    throw error;
  }
};


/**
 * Fetch jobs with dynamic conditions.
 * @param {Array} conditions - Array of condition objects { field, operator, value }.
 * @returns {Promise<Array>} - Array of jobs matching the criteria.
 */
export const fetchJobsWithConditions = async (conditions) => {
  try {
    console.log('Fetching jobs with conditions:', conditions);
    const jobsRef = collection(db, 'jobs'); // Reference to the jobs collection

    // Build the query dynamically based on conditions
    let jobsQuery = jobsRef;
    conditions.forEach((condition) => {
      jobsQuery = query(jobsQuery, where(condition.field, condition.operator, condition.value));
    });

    const querySnapshot = await getDocs(jobsQuery);

    const jobs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log('Fetched jobs with conditions:', jobs);
    return jobs;
  } catch (error) {
    console.error('Error fetching jobs with conditions:', error);
    throw error;
  }
};

/**
 * Fetch all Pre-Quoted Works (PQW) from the Firestore collection.
 * This function retrieves a list of pre-quoted work items, including their
 * title, job detail description, and price, for display in the landlord dashboard.
 * 
 * @returns {Promise<Array>} An array of PQW objects with the following fields:
 * - id: Unique document ID from Firestore.
 * - title: The name/title of the pre-quoted work.
 * - jobDetail: A description of the work to be performed.
 * - price: The cost of the work.
 */
export const fetchPreQuotedWorks = async () => {
  try {
    const pqwCollection = collection(db, 'preQuotedWorks');
    const snapshot = await getDocs(pqwCollection);
    const works = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return works;
  } catch (error) {
    console.error('dbService: Error fetching Pre-Quoted Works:', error);
    throw error;
  }
};

/**
 * Fetches all user record from the Firestore database.
 * @returns {Promise<Object|null>} - The user document data if found, or `null` if the user does not exist.
 * @throws {Error} - If an error occurs during the query.
 */
export const fetchAllUsers = async () => {
  try {
    const usersCollection = collection(db, 'users'); 
    const snapshot = await getDocs(usersCollection);
    const users = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return users;
  } catch (error) {
    console.error('dbService.js: Error fetching all users: ', error);
    throw error; // Rethrow to allow error handling in calling code
  }
};

/**
 * Insert a new job into the Firestore `jobs` collection.
 * This job is for pre-approved work and skips the quote process.
 *
 * @param {string} propertyId - The ID of the property the job is associated with.
 * @param {string} jobId - A unique job ID in the format propertyId-jobId.
 * @param {Object} jobData - The data for the job, including trade, title, detail, and price.
 * @returns {Promise<void>} A promise that resolves when the job is successfully added.
 */
export const insertPreApprovedJob = async (propertyId, jobId, jobData) => {
  try {
    const jobRef = doc(db, 'jobs', jobId);
    const newJob = {
      ...jobData,
      createdAt: serverTimestamp(),
      status: 2, // Pre-approved work
    };
    await setDoc(jobRef, newJob);
    console.log('dbService: Pre-approved job inserted successfully:', newJob);
  } catch (error) {
    console.error('dbService: Error inserting pre-approved job:', error);
    throw error;
  }
};

/**
 * Get the next available job ID for a given property.
 * Queries the `jobs` collection to find the highest existing job ID for the property
 * and returns the next sequential job ID.
 *
 * @param {string} propertyId - The ID of the property.
 * @returns {Promise<string>} The next job ID in the format propertyId-<nextNumber>.
 */
export const getNextJobId = async (propertyId) => {

  try {
    const jobsCollection = collection(db, 'jobs');
    const jobQuery = query(jobsCollection, where('propertyId', '==', propertyId));
    const snapshot = await getDocs(jobQuery);

    // Extract job numbers from IDs in the format propertyId-jobNumber
    const jobNumbers = snapshot.docs
      .map((doc) => doc.id.split('-')[1]) // Get job number part
      .map(Number) // Convert to number for sorting
      .filter((num) => !isNaN(num)); // Ensure valid numbers

    const nextJobNumber = jobNumbers.length > 0 ? Math.max(...jobNumbers) + 1 : 1;
    return `${propertyId}-${nextJobNumber}`;
  } catch (error) {
    console.error('dbService: Error calculating next job ID:', error);
    throw error;
  }
};

/**
 * Fetch a job by its ID.
 * @param {string} jobId - The ID of the job to fetch.
 * @returns {Promise<Object>} - The job record.
 */
export const fetchJobById = async (jobId) => {
  debugger;
  try {
    const jobRef = doc(db, "jobs", jobId);
    const jobSnapshot = await getDoc(jobRef);

    if (jobSnapshot.exists()) {
      return { id: jobSnapshot.id, ...jobSnapshot.data() };
    } else {
      throw new Error(`Job with ID ${jobId} does not exist.`);
    }
  } catch (error) {
    console.error("dbService: Error fetching job by ID:", error);
    throw error;
  }
};

/**
 * Fetches jobs with optional filters. By default, returns all jobs if no filters are specified.
 *
 * @param {Object} options - Optional filters (e.g., { landlordEmail: 'email@example.com', status: 'Approved' }).
 *   - `landlordEmail`: (string) Filter to return jobs associated with a specific landlord.
 *   - `status`: (any) Filter to return jobs with a specific status.
 * @returns {Promise<Array>} - An array of jobs matching the conditions.
 */
export const fetchJobsForAdminToPrice = async (options = {}) => {

  const jobsCollection = collection(db, 'jobs'); // Reference to the jobs collection

  try {
    // Start with the base collection reference.
    // By default, this will fetch all jobs if no filters are applied.
    let jobQuery = jobsCollection;
    jobQuery = query(jobQuery,
      where("status", "==", options.status),
      orderBy("createdAt"));

    // Execute the query and retrieve the matching documents
    const querySnapshot = await getDocs(jobQuery);

    // Map each document to an object and include its ID
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error; // Propagate error to the caller
  }
};

/**
 * Updates the status of a job in Firestore.
 *
 * @param {string} jobId - The ID of the job to update.
 * @param {number} status - The new status to set (-1 for rejected).
 * @returns {Promise<void>} - Resolves when the status is updated.
 */
export const updateJobStatus = async (jobId, status) => {
  try {
    const jobRef = doc(db, 'jobs', jobId);
    
    const updateData = { status };

    // If status is 2, include quoteAccepted and quoteAcceptedAt
    if (status === 2) {
      updateData.quoteAccepted = true;
      updateData.quoteAcceptedAt = new Date(); // Timestamp for now
    }

    await updateDoc(jobRef, updateData);
  } catch (error) {
    console.error(`Error updating status for job ${jobId}:`, error);
    throw error;
  }
};

/**
 * Fetches a user record from the Firestore database based on their email address.
 * @param {string} email - The email address of the user.
 * @returns {Promise<Object|null>} - The user document data if found, or `null` if the user does not exist.
 * @throws {Error} - If an error occurs during the query.
 */
export const fetchUserByEmail = async (email) => {
  try {
    const userDocRef = doc(db, 'users', email); // Correctly reference the document
    const userDoc = await getDoc(userDocRef); // Use getDoc to fetch the document
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() }; // Return the user data
    } else {
      return null; // User does not exist
    }
  } catch (error) {
    console.error('dbService.js: Error fetching user by email:', error);
    throw error; // Rethrow to allow error handling in calling code
  }
};

/**
 * Fetches the user document from the Firestore 'users' collection.
 *
 * @param {string} userEmail - The email address of the user whose document is to be fetched.
 * @returns {Promise<Object>} - A promise that resolves to the user document data.
 * @throws {Error} - Throws an error if the email is not provided, or if the document doesn't exist.
 *
 * Example usage:
 * const user = await fetchUserDocument('example@domain.com');
 * console.log(user.role); // Access specific fields like 'role'
 */
export const fetchUserDocument = async (userEmail) => {
  if (!userEmail) {
    throw new Error('User email is required to fetch user document');
  }

  try {
    const userDocRef = doc(db, 'users', userEmail);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error(`No user document found for email: ${userEmail}`);
    }

    return userDoc.data(); // Return the full user document
  } catch (error) {
    console.error('fetchUserDocument: Failed to fetch user document:', error);
    throw error;
  }
};

/**
 * Fetches the logged-in agent's profile data from the `users` collection.
 * @param {string} email - The email of the logged-in agent.
 * @returns {Promise<Object>} The agent's profile data.
 * @throws {Error} If no user document is found for the provided email.
 */
export const fetchAgentData = async (email) => {
  if (!email) {
    throw new Error('Email is required to fetch agent data.');
  }

  const userDocRef = doc(db, 'users', email);
  const userDoc = await getDoc(userDocRef);

  if (!userDoc.exists()) {
    throw new Error(`No user document found for email: ${email}`);
  }

  return userDoc.data();
};

/**
 * Updates the lastLoggedIn timestamp for a given user in Firestore.
 * @param {string} userEmail - The email of the user whose lastLoggedIn field is to be updated.
 * @returns {Promise<void>} - Resolves if the update is successful; rejects otherwise.
 */
export const updateLastLoggedIn = async (userEmail) => {
  if (!userEmail) throw new Error('User email is required to update lastLoggedIn.');

  const userDocRef = doc(db, 'users', userEmail);
  await updateDoc(userDocRef, {
    lastLoggedIn: new Date(),
  });
};

/**
 * Fetches a property by its ID and includes the associated user data.
 * @param {string} propertyId - The property's propertyId.
 * @returns {Promise<Object>} - The property details along with user data.
 */
export const fetchPropertyById = async (propertyId) => {
  try {
    // Fetch the property document
    const propertyRef = doc(db, "properties", propertyId);
    const propertySnapshot = await getDoc(propertyRef);

    if (!propertySnapshot.exists()) {
      throw new Error(`Property with ID ${propertyId} does not exist.`);
    }

    const propertyData = { id: propertySnapshot.id, ...propertySnapshot.data() };

    // Fetch the associated user document based on the email or user ID
    const userIdentifier = propertyData.landlordsEmail;
    if (!userIdentifier) {
      throw new Error("Property is missing user information landlordsEmail.");
    }

    const userRef = doc(db, "users", userIdentifier);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error(`User with ID/Email ${userIdentifier} does not exist.`);
    }

    const userData = { id: userSnapshot.id, ...userSnapshot.data() };

    // Combine property data and user data
    return { ...propertyData, user: userData };
  } catch (error) {
    console.error("dbService: Error fetching property with user data:", error);
    throw error;
  }
};


/**
 * Safely inserts a comment into the comments collection for a given job ID.
 * @param {string} jobId - The ID of the job.
 * @param {string} commentText - The text of the comment.
 * @param {string} sender - The sender of the comment (e.g., 'Admin').
 */
export const insertComment = async (jobId, commentText, sender = 'Admin') => {
  if (!jobId || typeof jobId !== 'string' || jobId.trim() === '') {
    console.error('Invalid jobId:', jobId);
    return;
  }

  const messageData = {
    sender,
    text: commentText,
    timestamp: serverTimestamp(),
  };

  try {
    const commentsRef = doc(db, 'comments', jobId);

    // Check if the document exists
    const commentsSnap = await getDoc(commentsRef);
    if (commentsSnap.exists()) {
      // Update existing document with a new comment
      await updateDoc(commentsRef, {
        comments: arrayUnion(messageData),
      });
      console.log('Comment added to existing document:', messageData);
    } else {
      // Create a new document with the comment
      await setDoc(commentsRef, {
        jobId,
        comments: [messageData],
      });
      console.log('Created new document with comment:', messageData);
    }
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

