import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { formatTimestamp } from '../Utilities/dateUtils';
import { fetchJobsForAdminToPrice } from '../services/dbService';

const QuotedWaitingOnLLAcceptance = ({ isSingleColumn, onRowSelectAction }) => {
  const [quotedJobs, setQuotedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsQuotedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { headerName: "TRADE", field: "tradeName", flex: 1.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    {
      headerName: "QUOTE DATE",
      field: "createdAt",
      flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value)
    }
  ];

  useEffect(() => {
    const loadQuotedJobs = async () => {
      const options = { status: 1 }; // Fetch jobs with status == 1 for quoted waiting on LL acceptance
      const jobs = await fetchJobsForAdminToPrice(options);
      setQuotedJobs(jobs);
    };

    loadQuotedJobs();
  }, []);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'readWrite'
    };

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <>
      <GridPanel
        title="Quoted Waiting on LL Acceptance - 1"
        columnDefs={columnDefsQuotedJobs}
        rowData={quotedJobs}
        height={panelHeight}
        onResize={(change) => setPanelHeight(panelHeight + change)}
        isSingleColumn={isSingleColumn}
        toggleLayout={() => setIsCollapsed(false)}
        isCollapsed={isCollapsed}
        toggleCollapse={() => setIsCollapsed(!isCollapsed)}
        onRowClick={handleJobRowClick} // Pass the row click handler
      />

    </>
  );
};

export default QuotedWaitingOnLLAcceptance;
