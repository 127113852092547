/**
 * Converts a Firestore timestamp to a formatted date string.
 *
 * @param {Object} timestamp - Firestore timestamp with seconds and nanoseconds.
 * @returns {string} - Formatted date string in 'DD/MM/YY HH:MM:SS' format.
 */
export const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString('en-GB', { 
          hour: '2-digit', 
          minute: '2-digit', 
          second: '2-digit', 
          day: '2-digit', 
          month: '2-digit', 
          year: '2-digit' 
      });
  }
  return '';
};

export const formatDateOnly = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: '2-digit', 
      year: '2-digit' 
    });
  }
  return '';
};