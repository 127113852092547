// src/components/UI/ImageUpload.js

import React, { useImperativeHandle, useRef } from 'react';

const ImageUpload = React.forwardRef(({ onFilesSelected }, ref) => {
  const inputRef = useRef(null); // Local ref for the file input

  // Expose a clearFiles method to the parent component through the ref
  useImperativeHandle(ref, () => ({
    clearFiles: () => {
      if (inputRef.current) {
        inputRef.current.value = ""; // Clear the file input
      }
    }
  }));

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    onFilesSelected(files); // Send selected files back to InviteLandlordForm
  };

  return (
    <div>
      <label>Upload Images</label>
      <input type="file" multiple onChange={handleFileChange} ref={inputRef} />
    </div>
  );
});

export default ImageUpload;
