import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';

const JobsAccepted = ({ isSingleColumn, onRowSelectAction }) => {
  const [acceptedJobs, setAcceptedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsAcceptedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE", field: "tradeName", flex: 1.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "ACCEPTED DATE", field: "acceptedAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadAcceptedJobs = async () => {
      const options = { status: 3 };
      const jobs = await fetchJobs(options);
      setAcceptedJobs(jobs);
    };

    loadAcceptedJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'readWrite'
    };

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <GridPanel
      title="Jobs Accepted - 3"
      columnDefs={columnDefsAcceptedJobs}
      rowData={acceptedJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick} // Pass the row click handler
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobsAccepted;
