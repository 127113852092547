import React, { useState, useEffect } from 'react';
import { updateDoc, doc, Timestamp } from 'firebase/firestore'; // Firestore
import { createUserWithEmailAndPassword, updatePassword, sendSignInLinkToEmail } from 'firebase/auth'; // Firebase Auth
import { auth, db } from '../firebase'; // Firebase instances
import { useNavigate } from 'react-router-dom'; // Navigation
import '../styles/CompleteProfile.css'; // Styles
import { SUBSCRIPTION_MAPPING, fetchUserDocument } from '../services/dbService';

const CompleteProfile = () => {
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyPostcode, setCompanyPostcode] = useState('');
  const [companyMainContactName, setCompanyMainContactName] = useState('');
  const [officePhoneNumber, setOfficePhoneNumber] = useState('');
  const [paymentBankName, setPaymentBankName] = useState('');
  const [paymentAccountNumber, setPaymentAccountNumber] = useState('');
  const [paymentSortCode, setPaymentSortCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [user, setUser] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const navigate = useNavigate();

  const currentUserEmail = auth.currentUser?.email || 'No user'; // Centralized email reference

  useEffect(() => {
    const fetchUser = async () => {
      if (currentUserEmail !== 'No user') {
        try {
          const userDoc = await fetchUserDocument(currentUserEmail);
          setUser(userDoc);
          setSubscriptionType(userDoc.subscriptionType || null);
        } catch (error) {
          console.error('Error fetching user document:', error);
        }
      }
    };

    fetchUser();
  }, [currentUserEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!currentUserEmail) {
      setError('No valid user found. Please log in again.');
      navigate('/login-user');
      return;
    }

    try {
      // Step 1: Attempt to update the user's password
      try {
        await updatePassword(auth.currentUser, newPassword);
      } catch (error) {
        console.error('Error updating password:', error);

        if (error.code === 'auth/requires-recent-login') {
          alert('Your session has expired. A new login link will be sent to your email.');

          // Resend email link for re-authentication
          try {
            const actionCodeSettings = {
              url: `${process.env.REACT_APP_SITE_URL}/complete-profile?email=${encodeURIComponent(currentUserEmail)}`,
              handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(auth, currentUserEmail, actionCodeSettings);
            alert('A new login link has been sent to your email.');
          } catch (resendError) {
            console.error('Error resending login link:', resendError);
            setError('Failed to resend login link. Please contact support.');
          }
          return; // Stop further processing
        } else if (error.code === 'auth/user-not-found') {
          console.log('User not found. Creating new account...');
          await createUserWithEmailAndPassword(auth, currentUserEmail, newPassword);
        } else {
          setError('Failed to update password. Please try again.');
          return;
        }
      }

      // Step 2: Update Firestore with profile info
      const userDocRef = doc(db, 'users', currentUserEmail);
      await updateDoc(userDocRef, {
        companyName,
        companyAddress,
        companyPostcode,
        companyMainContactName,
        officePhoneNumber,
        paymentBankName,
        paymentAccountNumber,
        paymentSortCode,
        isProfileComplete: true,
        lastLoggedIn: Timestamp.now(),
        inviteAcceptedAt: Timestamp.now(),
        subscriptionType, // Save selected subscription type
      });

      // Step 3: Navigate to the appropriate dashboard
      if (user?.role) {
        const targetRoute =
          user.role === 'Agent'
            ? '/agent-dashboard'
            : user.role === 'Landlord'
            ? '/landlord-dashboard'
            : user.role === 'Contractor' && subscriptionType !== '0'
            ? '/payment-processing'
            : '/contractor-dashboard'; // Platinum contractors go directly to their dashboard
        navigate(targetRoute);
      } else {
        setError('User role is missing. Please contact support.');
      }

      setSuccess('Profile updated successfully!');
    } catch (error) {
      console.error('Error during profile update:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  return (
    <div className="completeProfile-form-container">
      <h2>Complete Your Profile</h2>
      <form onSubmit={handleSubmit}>
        <label>Company Name:</label>
        <input
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />

        <div className="completeProfile-address-postcode-group">
          <div>
            <label>Company Address:</label>
            <input
              type="text"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
              required
            />
          </div>

          <div>
            <label>Company Postcode:</label>
            <input
              type="text"
              value={companyPostcode}
              onChange={(e) => setCompanyPostcode(e.target.value)}
              required
            />
          </div>
        </div>

        <label>Main Contact Name:</label>
        <input
          type="text"
          value={companyMainContactName}
          onChange={(e) => setCompanyMainContactName(e.target.value)}
          required
        />

        <label>Office Phone Number:</label>
        <input
          type="tel"
          value={officePhoneNumber}
          onChange={(e) => setOfficePhoneNumber(e.target.value)}
          required
        />

        <label>Bank Name:</label>
        <input
          type="text"
          value={paymentBankName}
          onChange={(e) => setPaymentBankName(e.target.value)}
          required
        />

        <label>Account Number:</label>
        <input
          type="text"
          value={paymentAccountNumber}
          onChange={(e) => setPaymentAccountNumber(e.target.value)}
          required
        />

        <label>Sort Code:</label>
        <input
          type="text"
          value={paymentSortCode}
          onChange={(e) => setPaymentSortCode(e.target.value)}
          required
        />

        <label>New Password:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />

        {user?.role === 'Contractor' && subscriptionType !== '0' && (
          <div className="contractor-subscription-panel">
            <h3>Select Your Subscription Type</h3>
            <div className="subscription-options">
              {Object.entries(SUBSCRIPTION_MAPPING)
                .filter(([key]) => key !== '0') // Exclude Platinum
                .map(([key, { labelShort, description, cost }]) => (
                  <div key={key} className="subscription-option">
                    <input
                      type="radio"
                      id={`subscription-${key}`}
                      name="subscriptionType"
                      value={key}
                      checked={subscriptionType === key}
                      onChange={(e) => setSubscriptionType(e.target.value)}
                    />
                    <label htmlFor={`subscription-${key}`}>
                      <strong>{labelShort}</strong>: {description} - £{cost}/month
                    </label>
                  </div>
                ))}
            </div>
          </div>
        )}

        {error && <p className="completeProfile-error-message">{error}</p>}
        {success && <p className="completeProfile-success-message">{success}</p>}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default CompleteProfile;
