// src/Panels/JobApprovedAwaitingContractor.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';
// import Distribute from '../Dashboard/DashboardDistribute';

const JobApprovedAwaitingContractor = ({ isSingleColumn, onRowSelectAction }) => {
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);
  
  const columnDefsApprovedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.5 },
    { headerName: "TRADE", field: "tradeName", flex: 0.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "APPROVED DATE", field: "approvedAt", flex: 0.5,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadApprovedJobs = async () => {
      const options = { status: 2 }; // Fetch jobs with status == 2 for approved jobs
      const jobs = await fetchJobs(options);
      setApprovedJobs(jobs);
    };

    loadApprovedJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'readWrite'
    };

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <>
    <GridPanel
      title="Job Approved Awaiting Contractor Allocation - 2"
      columnDefs={columnDefsApprovedJobs}
      rowData={approvedJobs}
      isSingleColumn={isSingleColumn}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick} // Pass the row click handler
    />

    </>
  );
};

export default JobApprovedAwaitingContractor;
