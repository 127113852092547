// src/components/SettingsTrades.js

import React, { useEffect, useState } from 'react';
import { fetchTrades, addTrade, updateTrade, deleteTrade } from '../services/tradesService';
import FlashMessage from '../components/UI/FlashMessage';

import '../styles/SettingsTrades.css';

const SettingsTrades = () => {
  const [trades, setTrades] = useState([]);
  const [newTrade, setNewTrade] = useState({ tradename: '', enabled: false });
  const [flashMessage, setFlashMessage] = useState('');

  useEffect(() => {
    const getTrades = async () => {
      const tradesData = await fetchTrades();
      tradesData.sort((a, b) => a.tradename.localeCompare(b.tradename));
      setTrades(tradesData);
    };
    getTrades();
  }, []);

  const showFlashMessage = (message) => {
    setFlashMessage(message);
    setTimeout(() => {
      setFlashMessage('');
    }, 3000); // Message will disappear after 3 seconds
  };

  const handleAddTrade = async () => {
    if (newTrade.tradename.trim()) {
      const addedTrade = await addTrade(newTrade);
      setTrades([...trades, addedTrade]);
      setNewTrade({ tradename: '', enabled: false });
      showFlashMessage('Trade added successfully');
    }
  };

  const handleUpdateTrade = async (id, updatedTrade) => {
    await updateTrade(id, updatedTrade);
    setTrades(trades.map(trade => (trade.id === id ? { ...trade, ...updatedTrade } : trade)));
    showFlashMessage('Trade updated successfully');
  };

  const handleDeleteTrade = async (id) => {
    await deleteTrade(id);
    setTrades(trades.filter(trade => trade.id !== id));
    showFlashMessage('Trade deleted successfully');
  };

  return (
    <div className="settings-trades-container">
      <h2>Manage Trades</h2>
      <div className="trade-form">
        <input
          type="text"
          placeholder="Add a Trade"
          value={newTrade.tradename}
          onChange={(e) => setNewTrade({ ...newTrade, tradename: e.target.value })}
        />
        <label>
          Enabled
          <input
            type="checkbox"
            checked={newTrade.enabled}
            onChange={(e) => setNewTrade({ ...newTrade, enabled: e.target.checked })}
          />
        </label>
        <button onClick={handleAddTrade}>Add Trade</button>
      </div>
      <ul className="trade-list">
        {trades.map((trade) => (


<li key={trade.id} className="trade-list-item">
  <input
    type="text"
    value={trade.tradename}
    onChange={(e) => handleUpdateTrade(trade.id, { tradename: e.target.value, enabled: trade.enabled })}
  />
  <label>
    Enabled
    <input
      type="checkbox"
      checked={trade.enabled}
      onChange={(e) => handleUpdateTrade(trade.id, { tradename: trade.tradename, enabled: e.target.checked })}
    />
  </label>
  <button onClick={() => handleUpdateTrade(trade.id, { tradename: trade.tradename, enabled: trade.enabled })}>
    Update
  </button>
  <button onClick={() => handleDeleteTrade(trade.id)}>Delete</button>

  {/* Flash Message Positioned Below Input */}
  <div className="trade-flash-message-container">
    {flashMessage && <FlashMessage message={flashMessage} />}
  </div>
</li>



        ))}
      </ul>
    </div>
  );
};

export default SettingsTrades;
