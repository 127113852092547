import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext';

// Early interception logic to handle email extraction
(function () {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');

  if (email) {
    // alert('index.js: Email parameter found: ' + email);
    localStorage.setItem('emailForSignIn', email);
    // alert('email stored');
  } else {
    // alert('index.js: No email parameter found in the URL.');
  }
})();

// Initialize React app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  // </React.StrictMode>
);
