// src/layouts/LayoutB.js
import React from 'react';
import Header from '../components/UI/Header'; 
import '../styles/SecondaryLayout.css'; 

const LayoutB = ({ children }) => {
  return (
    <div className="layout-b-container">
      <Header /> {/* Shared header component */}
      <main className="main-content">
        {children} {/* This will render the dynamic content, like AgentDashboard */}
      </main>
    </div>
  );
};

export default LayoutB;

