import React from 'react';

const CostBreakdownTable = ({ costRows, onRowChange, onAddRow, onRemoveRow, readOnly }) => {
  const handleVatChange = (id, value, net) => {
    const netValue = parseFloat(net) || 0;
    const vatAmount = value === '20%' ? (netValue * 0.2).toFixed(2) : 0;
    const total = (netValue + parseFloat(vatAmount)).toFixed(2);
    onRowChange(id, 'vatAmount', vatAmount);
    onRowChange(id, 'total', total);
    onRowChange(id, 'vatRate', value); // Save VAT rate to the row
  };

  return (
    <div>
      <h3>Contractor Cost Breakdown</h3>
      <table className="cost-breakdown-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Description</th>
            <th>Net £</th>
            <th>VAT £</th>
            <th>Total £</th>
            <th>
              {!readOnly && <button type="button" className="add-row-btn" onClick={onAddRow}>+</button>}
            </th>
          </tr>
        </thead>
        <tbody>
          {costRows.map((row) => (
            <tr key={row.id}>
              <td>
                <select 
                  value={row.type} 
                  onChange={(e) => onRowChange(row.id, 'type', e.target.value)} 
                  disabled={readOnly}
                >
                  <option>Labour</option>
                  <option>Materials</option>
                  <option>Other</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  placeholder="Description"
                  value={row.description || ''}
                  onChange={(e) => onRowChange(row.id, 'description', e.target.value)}
                  disabled={readOnly}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.net || ''} // Display blank if the value is null or undefined
                  onChange={(e) => {
                    const netValue = e.target.value;
                    onRowChange(row.id, 'net', netValue);
                    handleVatChange(row.id, row.vatRate || '20%', netValue);
                  }}
                  onFocus={(e) => e.target.select()} // Highlight text on focus
                />
              </td>
              <td>
                <select
                  value={row.vatRate || '20%'}
                  onChange={(e) => handleVatChange(row.id, e.target.value, row.net || 0)}
                  disabled={readOnly}
                >
                  <option value="20%">20%</option>
                  <option value="No VAT">No VAT</option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  value={parseFloat(row.total || 0).toFixed(2)} // Ensure two decimal places
                  readOnly
                />
              </td>
              <td>
                {!readOnly && <button type="button" onClick={() => onRemoveRow(row.id)}>🗑️</button>}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3"></td>
            <td>VAT Subtotal £</td>
            <td>{costRows.reduce((sum, row) => sum + parseFloat(row.vatAmount || 0), 0).toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan="3"></td>
            <td>Subtotal £</td>
            <td>{costRows.reduce((sum, row) => sum + parseFloat(row.net || 0), 0).toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan="3"></td>
            <td>Grand Total £</td>
            <td>
              {(
                costRows.reduce((sum, row) => sum + parseFloat(row.vatAmount || 0), 0) +
                costRows.reduce((sum, row) => sum + parseFloat(row.net || 0), 0)
              ).toFixed(2)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default CostBreakdownTable;
