import React from 'react';
import Header from '../components/UI/Header';
import SideNav from '../components/UI/SideNav';
import AdministrationContent from './AdministrationContent';

const MainLayout = ({ isCollapsed, setIsCollapsed, isDarkMode, toggleDarkMode, isContractor }) => {

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Header />
      <SideNav
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        isContractor={isContractor}
      />
      <AdministrationContent isCollapsed={isCollapsed} isContractor={isContractor}/>
    </div>
  );
};


export default MainLayout;
