// src/services/preQuotedWorksService.js

import { db } from '../firebase';
import { doc, updateDoc, deleteDoc, collection, getDocs, addDoc } from 'firebase/firestore';

const preQuotedWorksCollection = collection(db, 'preQuotedWorks');
const tradesCollection = collection(db, 'trades'); // Collection reference for trades

// Fetch all enabled Pre Quoted Works
export const fetchPreQuotedWorks = async () => {
    const querySnapshot = await getDocs(preQuotedWorksCollection);
    return querySnapshot.docs
        .filter(doc => doc.data().enabled) // Only fetch enabled entries
        .map(doc => ({ id: doc.id, ...doc.data() }));
};

// Fetch all enabled trades
export const fetchTrades = async () => {
    const querySnapshot = await getDocs(tradesCollection);
    return querySnapshot.docs
        .filter(doc => doc.data().enabled)
        .map(doc => ({ id: doc.id, ...doc.data() }));
};

// Add a new Pre Quoted Work
export const addPreQuotedWork = async (pqwData) => {
    const docRef = await addDoc(preQuotedWorksCollection, pqwData);
    console.log('preQuotedWorksService: addPreQuotedWork:', pqwData);
    return { id: docRef.id, ...pqwData };
};

// Update an existing Pre Quoted Work by ID
export const updatePreQuotedWork = async (id, updatedData) => {
    const docRef = doc(db, 'preQuotedWorks', id);
    await updateDoc(docRef, updatedData);
    console.log('preQuotedWorksService: updatePreQuotedWork:', { id, ...updatedData });
    return { id, ...updatedData };
};

// Delete a Pre Quoted Work by ID
export const deletePreQuotedWork = async (id) => {
    const docRef = doc(db, 'preQuotedWorks', id);
    await deleteDoc(docRef);
    console.log('preQuotedWorksService: deletePreQuotedWork:', id);
};
