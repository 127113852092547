import React, { useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import PopForm from '../PopForm/PopForm';

const Calendar = ({ events, onEventAdd, onEventChange, onEventRemove }) => {

  const [isPopFormOpen, setPopFormOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateClick = (info) => {
    setSelectedDate(info.dateStr);
    setPopFormOpen(true);
  };

  const closePopForm = () => setPopFormOpen(false);


  return (
    <div>
    
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        events={events}
        eventAdd={onEventAdd}
        eventChange={onEventChange}
        eventRemove={onEventRemove}
        dateClick={handleDateClick}
      />

      <PopForm isOpen={isPopFormOpen} onClose={closePopForm} title={`Details for ${selectedDate}`}>
        {/* Content or form elements */}
      </PopForm>
    
    </div>

  );
};

export default Calendar;
