import { db } from '../firebase'; // src/firebase.js
import { doc, runTransaction } from 'firebase/firestore';
import { collection, query, where, getDocs } from 'firebase/firestore';



/**
 * Fetches properties based on the landlord's email address.
 * @param {string} email - The landlord's email address.
 * @returns {Promise<Array>} - An array of property details.
 */
export const getPropertiesByEmail = async (landlordsEmail) => {
  try {
    const propertiesRef = collection(db, 'properties');
    const q = query(propertiesRef, where('landlordsEmail', '==', landlordsEmail));

    const querySnapshot = await getDocs(q);

    const properties = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return properties;
  } catch (error) {
    console.error('Failed to fetch properties by Landlords Email:', error);
    throw error;
  }
};

/**
 * Gets the next available property ID from the propertyMeta document.
 */
export const getNextPropertyId = async () => {
  const metaDocRef = doc(db, 'propertyMeta', 'counter');

  try {
    const nextId = await runTransaction(db, async (transaction) => {
      const metaDoc = await transaction.get(metaDocRef);

      if (!metaDoc.exists()) {
        throw new Error('Property meta document does not exist!');
      }

      const currentId = metaDoc.data().current;
      const newId = currentId + 1;

      // Update the counter
      transaction.update(metaDocRef, { current: newId });

      return `M${newId}`;
    });

    return nextId;
  } catch (error) {
    console.error('Failed to get next property ID:', error);
    throw error;
  }
};
