import React from 'react';

const JobTopPanel = ({ jobId, landlord, agent, status }) => (
  <div className="job-top-panel">
    <h2>
      Replace bathroom basin hot tap <span style={{ float: 'right' }}>Job ID: {jobId}</span>
    </h2>
    <section className="job-info">
      <div><strong>Landlord:</strong> {landlord}</div>
      <div><strong>Agents:</strong> {agent}</div>
      <div><strong>Status:</strong> {status}</div>
    </section>

    <div className="action-buttons">
      <button className="decline-button">Return to Dashboard</button>
      <button className="accept-button">Accept Job</button>
    </div>
  </div>
);

export default JobTopPanel;
