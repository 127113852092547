import { globalConfig } from '../config';

/**
 * Generates the HTML content for a quote email.
 * @param {string} jobId - The Job ID for which the quote is provided.
 * @param {string} landlordName - The name of the landlord receiving the email.
 * @returns {string} - The HTML content for the email.
 */
export const generateQuoteEmailTemplate = (jobId, landlordName) => {
  return `
    <html>
      <body style="font-family: Arial, sans-serif; line-height: 1.6;">
        <div style="max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ddd;">
          <h2 style="color: #333;">Hello ${landlordName},</h2>
          <p>
            A new quote has been provided for your job, <strong>Job ID: ${jobId}</strong>.
          </p>
          <p>
            Please log in to your account to review the details and take the next steps.
          </p>
          <a href="${globalConfig.siteUrl}/dashboard?job=${jobId}" 
             style="display: inline-block; margin-top: 20px; padding: 10px 15px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px;">
            View Quote Now
          </a>
          <p style="margin-top: 20px; color: #555;">
            If you have any questions, don’t hesitate to contact us via the platform.
          </p>
          <p style="margin-top: 20px; font-size: 0.9em; color: #888;">
            Best regards, <br/>
            The ${globalConfig.sitename} Team
          </p>
        </div>
      </body>
    </html>
  `;
};
