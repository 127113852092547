// src/services/quoteService.js
import { db } from '../firebase';
import { collection, doc, setDoc, updateDoc, getDoc, serverTimestamp } from 'firebase/firestore';

/**
 * Inserts a new quote into the 'quotes' collection and updates the corresponding job's status.
 * @param {string} jobId - The ID of the job for which the quote is being created.
 * @param {Object} quoteData - The data for the quote to be inserted.
 * @returns {Promise} - A promise that resolves when the operation completes.
 */
export const createQuote = async (jobId, quoteData) => {
  try {
    console.log('quoteService: Creating quote with data:', quoteData);
    const quoteRef = doc(collection(db, 'quotes'), jobId);
    const quotePayload = {
      ...quoteData,
      createdAt: serverTimestamp(),
    };
    await setDoc(quoteRef, quotePayload);
    console.log('quoteService: Quote created successfully for jobId:', jobId);

    const jobRef = doc(db, 'jobs', jobId);
    await updateDoc(jobRef, { status: 1 });
    console.log('quoteService: Job status updated to 1 for jobId:', jobId);

    console.log('quoteService: Mock email sent to landlord:', quoteData.landlordEmail);
  } catch (error) {
    console.error('quoteService: Error creating quote:', error);
    throw new Error('Failed to create quote and update job status.');
  }
};

/**
 * Fetches an existing quote from the 'quotes' collection using the job ID.
 * @param {string} jobId - The ID of the job for which the quote is being fetched.
 * @returns {Promise<Object|null>} - The quote data if found, otherwise null.
 */
export const fetchQuote = async (jobId) => {
  try {
    const quoteRef = doc(db, 'quotes', jobId);
    const quoteSnapshot = await getDoc(quoteRef);

    if (quoteSnapshot.exists()) {
      console.log('quoteService: Quote data retrieved for jobId:', jobId);
      return quoteSnapshot.data(); // Return the quote data
    } else {
      console.log('quoteService: No quote found for jobId:', jobId);
      return null; // No document found
    }
  } catch (error) {
    console.error('quoteService: Error fetching quote:', error);
    throw new Error('Failed to fetch quote data.');
  }
};

/**
 * Mock function to notify landlord about the new quote submission.
 * 
 * @param {string} landlordEmail - The email of the landlord to notify.
 * @param {string} jobId - The job ID associated with the quote.
 */
export const notifyLandlord = (landlordEmail, jobId) => {
  console.log(`Notification sent to landlord at ${landlordEmail} for job ID: ${jobId}`);
  // In the future, integrate actual email or notification service here.
};
