import React from "react";
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import ContractorDashboard from "../Contractor/ContractorDashboard";
import Settings from '../Settings/Settings';
import Job from '../Job/Job';
import '../styles/AdministrationContent.css';

function AdministrationContent({ isCollapsed, isContractor }) {
  
  return (
    <div className={`page-content ${isCollapsed ? 'collapsed' : ''}`}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contractor-dashboard" element={<ContractorDashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/job" element={<Job />} />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
}

export default AdministrationContent;