// src/services/TradesService.js

import { db } from '../firebase';
import { collection, doc, addDoc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';

// Fetch all trades
export const fetchTrades = async () => {
  const tradesSnapshot = await getDocs(collection(db, 'trades'));
  return tradesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Add a new trade
export const addTrade = async (tradeData) => {
  const docRef = await addDoc(collection(db, 'trades'), tradeData);
  return { id: docRef.id, ...tradeData };
};

// Update a trade
export const updateTrade = async (id, tradeData) => {
  const tradeRef = doc(db, 'trades', id);
  await updateDoc(tradeRef, tradeData);
};

// Delete a trade
export const deleteTrade = async (id) => {
  const tradeRef = doc(db, 'trades', id);
  await deleteDoc(tradeRef);
};
