import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext'; 
import CostBreakdownTable from './CostBreakdownTable';
import { createQuote, notifyLandlord, fetchQuote } from '../services/quoteService';
import { updateJobStatus } from '../services/dbService';
import '../styles/QuoteForm.css';
import { doc, updateDoc } from 'firebase/firestore'; // For Firestore document references and updates
import { db } from '../firebase'; // Adjust the path if needed to import your Firebase configuration
import { serverTimestamp } from 'firebase/firestore';
import { SystemCommenter} from '../Shared/SystemCommenter'
import { generateQuoteEmailTemplate } from '../EmailTemplates/emailTemplates'; // Update path if needed
import { globalConfig } from '../config';
import { sendNotificationEmail } from "../Shared/EmailClient";

const QuoteForm = ({ jobData, onClose, readOnly = false, }) => {
// job status 
  debugger;
  const [quoteDetails, setQuoteDetails] = useState('');
  const [remainingChars, setRemainingChars] = useState(4000);
  const [costRows, setCostRows] = useState([
    { id: 1, type: 'Labour', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }
  ]);
  const [leadTime, setLeadTime] = useState('');
  const [duration, setDuration] = useState('');
  const [quoteExpiryDate, setQuoteExpiryDate] = useState(null);
  const [submitMessage, setSubmitMessage] = useState('');
  const [totalQuoteAmount, setTotalQuoteAmount] = useState(0);
  const { user } = useAuthContext();

  useEffect(() => {
    const loadData = async () => {
      if (readOnly && jobData) {
        try {
          const quoteData = await fetchQuote(jobData.jobId);
          if (quoteData) {
            setQuoteDetails(quoteData.quoteDetails);
            setLeadTime(quoteData.leadTime);
            setDuration(quoteData.duration);
            setQuoteExpiryDate(
              quoteData.quoteExpiryDate
                ? new Date(quoteData.quoteExpiryDate).toISOString().split('T')[0]
                : null
            );
            setCostRows(quoteData.costBreakdown || []);
          }
        } catch (error) {
          console.error('Error fetching quote details:', error);
        }
      }
    };

    loadData();
  }, [jobData, readOnly]);

  const handleQuoteDetailsChange = (e) => {
    if (!readOnly) {
      const text = e.target.value;
      setQuoteDetails(text);
      setRemainingChars(4000 - text.length);
    }
  };

  const addCostRow = () => {
    if (!readOnly) {
      setCostRows([
        ...costRows,
        { id: Date.now(), type: 'Labour', quantity: '', unitPrice: '', vatAmount: '', net: 0, total: 0 }
      ]);
    }
  };

  const removeCostRow = (id) => {
    if (!readOnly) {
      setCostRows(costRows.filter((row) => row.id !== id));
    }
  };

  const handleRowChange = (id, field, value) => {
    if (!readOnly) {
      setCostRows((prevRows) => {
        const updatedRows = prevRows.map((row) => {
          if (row.id === id) {
            const updatedRow = { ...row };
            updatedRow[field] = value;
  
            const net = parseFloat(updatedRow.net) || 0;
            const vatAmount = parseFloat(updatedRow.vatAmount) || 0;
            updatedRow.total = net + vatAmount;
  
            return updatedRow;
          }
          return row;
        });
  
        // Calculate the total based on the updated rows
        const newTotalAmount = updatedRows.reduce(
          (sum, row) => sum + (parseFloat(row.total) || 0),
          0
        );
        setTotalQuoteAmount(newTotalAmount);
  
        return updatedRows;
      });
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!readOnly) {
      const currentTime = serverTimestamp();

      const quoteData = {
        jobId: jobData.jobId,
        landlordEmail: jobData.landlordEmail,
        trade: jobData.tradeName,
        instructionNotes: jobData.instructionNotes,
        furtherDetails: jobData.furtherDetails,
        leadTime,
        duration,
        quoteExpiryDate: quoteExpiryDate ? new Date(quoteExpiryDate).getTime() : null,
        quoteDetails,
        costBreakdown: costRows,
        createdAt: currentTime,
        submittedBy: 'adminUser',
      };

      try {

        // ---------- comment from here to stop data changes

        await createQuote(jobData.jobId, quoteData);

        const jobRef = doc(db, 'jobs', jobData.jobId); // Reference to the job document
        await updateDoc(jobRef, {
          status: 1,
          quoteAmount: totalQuoteAmount,
          quoteTime: currentTime,
        });

        await SystemCommenter(
          jobData.jobId, 
          'Admin', 
          'A quote has been provided and sent for this job.'
        );
        
         notifyLandlord(jobData.landlordEmail, jobData.jobId);

         // ---------- End comment from here to stop data changes

        const plainTextBody = "A new quote has been provided for your job. Please log in to your account to review the details.";
        const htmlBody = generateQuoteEmailTemplate(jobData.jobId || "Valued Landlord");

        await sendNotificationEmail({
          to: jobData.landlordEmail,
          subject: `${globalConfig.sitename} quote - Job ${jobData.jobId}`,
          text: plainTextBody, // Plain-text email version
          html: htmlBody,      // HTML email version
        });

        setSubmitMessage('Quote submitted and job status updated successfully!');
        setTimeout(() => {
          setSubmitMessage('');
          onClose();
        }, 3000);
      } catch (error) {
        console.error('Error submitting quote or updating job status:', error);
        setSubmitMessage('Error submitting quote or updating job status. Please try again.');
      }
    }
  };

  const handleRejectQuote = async () => {
    try {
      await updateJobStatus(jobData.jobId, -1);
      onClose();
    } catch (error) {
      console.error('Error rejecting the quote:', error);
      alert('Failed to reject the quote. Please try again.');
    }
  };

  const handleAcceptQuote = async () => {
    try {
      // Update job status to '2' (Accepted)
      await updateJobStatus(jobData.jobId, 2); // Status '2' represents an accepted quote.
  
      // Notify the landlord or perform any other relevant actions
      notifyLandlord(jobData.landlordEmail, `Quote for Job ${jobData.jobId} has been accepted.`);
  
      // Provide feedback and close the form
      setSubmitMessage('Quote accepted successfully!');
      setTimeout(() => {
        setSubmitMessage('');
        onClose(); // Close the PopForm
      }, 3000);
    } catch (error) {
      console.error('Error accepting the quote:', error);
      alert('Failed to accept the quote. Please try again.');
    }
  };
  
  return (
    <form className="quote-form" onSubmit={handleSubmit}>
      <h2>{jobData.jobId}: Work Required</h2>

      <div className="form-row">
        <div className="form-group-job-details">
          <p>
            <span className="quote-form-title">Trade: </span>
            {jobData?.tradeName === 'Other (please specify)'
              ? jobData?.instructionNotes || 'N/A'
              : jobData?.tradeName}
          </p>
          <p>
            <span className="quote-form-title">Job Description:</span> {jobData?.jobDetail}
          </p>
          <p>
            <span className="quote-form-title">Further Information:</span> {jobData?.furtherDetails}
          </p>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label>Lead time required</label>
          <input
            type="text"
            placeholder="e.g. 1 day or 2 weeks"
            value={leadTime}
            onChange={(e) => setLeadTime(e.target.value)}
            disabled={readOnly}
          />
          <small>How much notice is needed</small>
        </div>
        <div className="form-group">
          <label>Est. duration</label>
          <input
            type="text"
            placeholder="e.g. 30 mins or hour"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            disabled={readOnly}
          />
          <small>How long would the job take</small>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group half-width">
          <label>Date quote expires</label>
          <input
            type="date"
            value={quoteExpiryDate || ''}
            onChange={(e) => setQuoteExpiryDate(e.target.value)}
            disabled={readOnly}
          />
          <small>When will the quote expire? Leave blank if not applicable</small>
        </div>
      </div>

      <div className="textarea-group">
        <label>Quote Detail</label>
        <textarea
          rows="4"
          placeholder="Enter details of the works included in the quote..."
          value={quoteDetails}
          onChange={handleQuoteDetailsChange}
          disabled={readOnly}
        />
        <div className="textarea-info">
          <small className="left-info">Details of works included in the quote</small>
          <small className="right-info">{remainingChars} characters remaining</small>
        </div>
      </div>

      <CostBreakdownTable
        costRows={costRows}
        onRowChange={handleRowChange}
        onAddRow={addCostRow}
        onRemoveRow={removeCostRow}
        readOnly={readOnly}
      />



       {/* Logic needed here for multiple case */}


      {!readOnly && user?.role === 'Admin' && (
        <div className="submit-btn">
          <button type="submit">Submit Quote</button>
        </div>
      )}

      {/* check the users RBAC role is allowed to view */}
      {!readOnly && user?.role === 'Landlord' && jobData.status === 1 && (
        <div className="quote-acceptance-row">
          <button className="negative-button" onClick={handleRejectQuote}>
            Reject Quote
          </button>
          <button className="positive-button" onClick={handleAcceptQuote}>
            Accept Quote and Pay
          </button>
        </div>
      )}

      {submitMessage && <div className="submit-message">{submitMessage}</div>}
    </form>
  );
};

export default QuoteForm;
