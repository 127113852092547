// src/containers/pages/SettingsPage.js

import React, { useState } from 'react';
import SettingsLanding from './SettingsLanding';
import SettingsAddUser from './SettingsAddUser';
import SettingsUsers from './SettingsUsers';
import SettingsDataVisibility from './SettingsDataVisibility';
import SettingsTrades from './SettingsTrades';
import SettingsPreQuotedWorks from './SettingsPreQuotedWorks';
import PopForm from '../PopForm/PopForm';
import '../styles/Settings.css';
import '../styles/PopForm.css';

const SettingsPage = () => {
  const [activePage, setActivePage] = useState('Landing'); // Track active page
  const [isPopFormVisible, setIsPopFormVisible] = useState(false);
  const [formType, setFormType] = useState('');

  const openForm = (type) => {
    setFormType(type);
    setIsPopFormVisible(true);
  };

  const closeForm = () => {
    setIsPopFormVisible(false);
  };

  function getFormTitle(type) {
    switch (type) {
      case 'QuoteForm':
        return 'Quotes';
      case 'Appointment':
        return 'Appointment';
      case 'JobRequestForm':
        return 'Job Request';
      default:
        return 'Form';
    }
  }

  return (
    <div className="settings-page">
      <div className="second-column-nav">
        <ul>
          <li onClick={() => setActivePage('AddUser')}>Add User</li>
          <li onClick={() => setActivePage('Users')}>Users</li>
          <li onClick={() => setActivePage('DataVisibility')}>Data Visibility</li>
          <li onClick={() => openForm('QuoteForm')}>Quote Form</li>
          <li onClick={() => openForm('Appointment')}>Appointment</li>
          <li onClick={() => openForm('JobRequest')}>Job Request</li>
          <li onClick={() => setActivePage('Trades')}>Trades</li>
          <li onClick={() => setActivePage('PreQuotedWorks')}>Pre Quoted Works</li>
          <li onClick={() => setActivePage('Landing')}>Home</li>

        </ul>
      </div>

      <div className="content-section">
        {activePage === 'Landing' && <SettingsLanding />}
        {activePage === 'AddUser' && <SettingsAddUser />}
        {activePage === 'Users' && <SettingsUsers />}
        {activePage === 'DataVisibility' && <SettingsDataVisibility />}
        {activePage === 'Trades' && <SettingsTrades />}
        {activePage === 'PreQuotedWorks' && <SettingsPreQuotedWorks />}
      </div>

      {isPopFormVisible && (
        <div className="pop-form-overlay" onClick={closeForm}>
          <div onClick={(e) => e.stopPropagation()}>
            <PopForm formType={formType} title={getFormTitle(formType)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
