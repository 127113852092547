import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import useAuth from './hooks/useAuth'; // Import the useAuth hook
import AppRouter from './AppRouter'; // Import the refactored router
import './styles/App.css';

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark-mode') === 'true');
  const { isAuthenticated, userEmail } = useAuth();

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle('light-mode', !newMode);
      localStorage.setItem('dark-mode', newMode ? 'true' : 'false');
      return newMode;
    });
  };

  return (
    <Router>
      <AppRouter
        isAuthenticated={isAuthenticated}
        userEmail={userEmail}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
      />
    </Router>
  );
}

export default App;
