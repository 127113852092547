// src/Panels/DateAcceptedAwaitingStartDate.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp } from '../Utilities/dateUtils';

const DateAcceptedAwaitingStartDate = ({ isSingleColumn, onRowClick }) => {
  const [awaitingStartDateJobs, setAwaitingStartDateJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsAwaitingStartDateJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "TRADE", field: "tradeName", flex: 1.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "DATE ACCEPTED", field: "acceptedAt", flex: 1.4,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadAwaitingStartDateJobs = async () => {
      const options = { status: 5 };
      const jobs = await fetchJobs(options);
      setAwaitingStartDateJobs(jobs);
    };

    loadAwaitingStartDateJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  return (
    <GridPanel
      title="Date Accepted - Awaiting Start -5"
      columnDefs={columnDefsAwaitingStartDateJobs}
      rowData={awaitingStartDateJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={onRowClick}
      isSingleColumn={isSingleColumn}
    />
  );
};

export default DateAcceptedAwaitingStartDate;
