import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'; // Import Firebase authentication methods
import { auth } from '../../firebase'; // Firebase auth instance
import '../../styles/LoginForm.css';

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError(null)
    
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful:', userCredential.user);
      
      if (onLogin) {
        await onLogin(userCredential.user); 
      }
    } catch (error) {
      console.error('Login error:', error.code, error.message);
      if (error.code === 'auth/user-not-found') {
        setError('User not found. Please check your email.');
      } else if (error.code === 'auth/wrong-password') {
        setError('Invalid password. Please try again.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email format.');
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    }
  };  

  return (
    <div className="login-page">
      {/* Branding Row */}
      <div className="branding-row">
        <img src="/mmg-logo.png" alt="MMG Logo" className="branding-logo" />
      </div>
      <div className="branding-row">
        <img src="/header-name.png" alt="MMG Branding" className="branding-text" />
      </div>

      {/* Login Form */}
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleLoginSubmit}>
          <label>Email:</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />

          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />

          {error && <p style={{ color: 'red' }}>{error}</p>}

          <button className="toggle-button" type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
