import React from 'react';

/**
 * PreQuotedWorksPanel Component
 * Displays the "Pre-Quoted Works" section of the landlord dashboard.
 */
const PreQuotedWorksPanel = ({ preQuotedWorks, handlePQWClick }) => {
  return (
    <div className="pre-quoted-works-panel">
      <h2>Discounted Pre Quoted Works</h2>
      <div className="buttons-row">
        {preQuotedWorks.map((work) => (
          <button
            key={work.id}
            className="negative-button"
            aria-label={`Pre-quoted work: ${work.title}`}
            onClick={() => handlePQWClick(work)}
          >
            <div className="pre-quoted-work-title">{work.title}</div>
          </button>
        ))}
      </div>
      <div className="request-quote-panel-description">
        Here you can choose from our wide selection of pre-quoted work. These jobs do not need to be assessed.
      </div>
    </div>
  );
};

export default PreQuotedWorksPanel;
