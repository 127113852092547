import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase"; // Adjust the path to your Firebase configuration

// Callable reference to the Firebase Cloud Function
const sendEmail = httpsCallable(functions, "sendEmail");

// Function to send a notification email
export const sendNotificationEmail = async ({ to, subject, text, html }) => {
  console.log({ to, subject, text, html }); // Debugging: Log the email payload

  if (!to || !subject || !html) {
    console.error("sendNotificationEmail: Missing required parameters.");
    return;
  }

  try {
    // Use the callable function to send the email
    const response = await sendEmail({
      to,
      subject,
      text,  // Plain-text version
      html,  // HTML version
    });

    console.log("sendNotificationEmail: Email sent successfully.", response);
  } catch (error) {
    console.error("sendNotificationEmail: Error sending email:", error);
  }
};
