import React, { useState, useEffect } from "react";
import { fetchJobById } from "../services/dbService";
import { STATUS_MAPPING } from '../services/dbService';

const JobDetailForm = ({jobId}) => {

  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  useEffect(() => {
    const loadJob = async () => {
      try {
        const fetchedJob = await fetchJobById(jobId);
        setJob(fetchedJob);
        setLoading(false);
      } catch (err) {
        console.error("JobDetailForm: Error loading job:", err);
        setError("Failed to load job details. Please try again later.");
        setLoading(false);
      }
    };

    loadJob();
  }, [jobId]);

  if (loading) return <p>Loading job details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="job-detail-form-container">
      <form>
        <label htmlFor="fault-detail">Job Description</label>
        <textarea
          id="fault-detail"
          name="fault-detail"
          rows="2"
          value={job?.jobDetail || ""}
          readOnly
        ></textarea>
  
        <label htmlFor="job-detail-instruction-notes">Further Information</label>
        <textarea
          id="instruction-notes"
          name="instruction-notes"
          rows="2"
          value={job?.furtherDetails || ""}
          readOnly
        ></textarea>
  
        <div className="job-detail-form-row">
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <input
              type="text"
              id="category"
              name="category"
              placeholder="Enter category of the request"
              value={job?.tradeName || ""}
              readOnly
            />
          </div>
  
          <div className="job-detail-form-group">
            <label htmlFor="created">Created</label>
            <input
              type="text"
              id="created"
              name="created"
              value={job?.createdAt ? new Date(job.createdAt.seconds * 1000).toLocaleDateString("en-GB") : ""}
              readOnly
            />
          </div>

          <div className="job-detail-form-group">
            <label htmlFor="isPQW">Pre-Quoted Work</label>
            <input
              type="text"
              id="isPQW"
              name="isPQW"
              value={job?.isPQW ? "Yes" : "No"}
              readOnly
            />
          </div>  

          <div className="job-detail-form-group">
            <label htmlFor="status">Status</label>
            <input
              type="text"
              id="status"
              name="status"
              value={STATUS_MAPPING[job?.status]?.label || STATUS_MAPPING.default.label}
              readOnly
            />
          </div>          
        </div>
      </form>
    </div>
  );
  
};

export default JobDetailForm;
