// src/components/UI/FlashMessage.js

import React, { useEffect, useState } from 'react';

const FlashMessage = ({ message, duration = 1500, color = 'success', onClose }) => {
    const [visible, setVisible] = useState(true);
    const [fading, setFading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFading(true); // Start fade-out
            setTimeout(() => {
                setVisible(false); // Hide after fade-out
                onClose && onClose();
            }, 300); // Fade-out duration
        }, duration);

        return () => clearTimeout(timer); // Cleanup
    }, [duration, onClose]);

    if (!visible) return null;

    return (
        <div className={`flash-message ${color} ${fading ? 'fade-out' : ''}`}>
            {message}
        </div>
    );
};

export default FlashMessage;
