// src/Dashboard.js

import React, { useState } from 'react';
import '../styles/Dashboard.css';
import JobsAvailable from '../Panels/JobsAvailable';
import JobsAccepted from '../Panels/JobsAccepted';
import StartDateOffered from '../Panels/StartDateOffered';
import DateAcceptedAwaitingStartDate from '../Panels/DateAcceptedAwaitingStartDate';
import JobInProgress from '../Panels/JobInProgress';
import JobCompletedInvoiceSent from '../Panels/JobCompletedInvoiceSent';
import JobCompletedClosed from '../Panels/JobCompletedClosed';
import JobCancelled from '../Panels/JobCancelled';
import PopForm from '../PopForm/PopForm';

const ContractorDashboard = () => {
  const [isSingleColumn] = useState(false);
  const [isPopFormVisible, setIsPopFormVisible] = useState(false);
  const [formType, setFormType] = useState('');
  const [selectedJob, setSelectedJob] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const openForm = (type, jobData, readOnly = false) => {
    setFormType(type);
    setSelectedJob(jobData);
    setIsReadOnly(readOnly); // Set the read-only state
    setIsPopFormVisible(true);
  };

  const closeForm = () => {
    setIsPopFormVisible(false);
  };

  return (
    <div>
      <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
        <JobsAvailable isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
        <JobsAccepted isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
      </div>

      <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
        <StartDateOffered isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
        <DateAcceptedAwaitingStartDate isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
      </div>

      <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
        <JobInProgress isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
        <JobCompletedInvoiceSent isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
      </div>

      <div className={`dashboard-container ${isSingleColumn ? 'single-column' : 'two-columns'}`}>
        <JobCompletedClosed isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
        <JobCancelled isSingleColumn={isSingleColumn} onRowSelectAction={(jobData) => openForm('QuoteForm', jobData)} />
      </div>

      {isPopFormVisible && (
        <div className="pop-form-overlay" onClick={closeForm}>
          <div onClick={(e) => e.stopPropagation()} className="pop-form-container">
            

            {/* TODO: CHANGES THIS SO ITS NOT FIXED AS QUOTE FORM */}
          <PopForm formType={formType} title="Quote Form" onClose={closeForm} jobData={selectedJob} readOnly={isReadOnly} />

          </div>
        </div>
      )}
    </div>
  );
};

export default ContractorDashboard;
