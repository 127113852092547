// src/components/GridPanel.js

import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';

const GridPanel = ({
  title,
  height,
  onResize,
  isSingleColumn,
  toggleLayout,
  isCollapsed,
  toggleCollapse,
  columnDefs,
  rowData,
  footerButton,
  refresh,
  onRowClick, // Ensure this matches the prop passed in
  refreshGrid,
}) => {
  useEffect(() => {
    if (refresh) {
      console.log('GridPanel: Data refreshed');
      // Trigger data reload here if needed
    }
  }, [refresh]);

  // Updated to use the onRowClick prop
  const handleRowClick = (event) => {
    console.log('GridPanel: Row clicked:', event.data); // Debugging log
    if (onRowClick) {
      onRowClick(event); // Call the action with the row data
    }
  };

  return (
    <div
      className={`grid-panel ${isCollapsed ? 'collapsed' : ''}`}
      style={{ height: isCollapsed ? '35px' : `${height}px` }}
    >
      <div className="title-panel">
        <h4>{title}</h4>
        <button
          className="collapse-btn"
          onClick={toggleCollapse}
          style={{ float: 'right' }}
          aria-expanded={!isCollapsed}
          aria-label={isCollapsed ? 'Expand grid' : 'Collapse grid'}
        >
          {isCollapsed ? (
            <i className="fa-solid fa-chevron-down" aria-hidden="true"></i>
          ) : (
            <i className="fa-solid fa-chevron-up" aria-hidden="true"></i>
          )}
        </button>
      </div>

      {!isCollapsed && (
        <div className="ag-grid-container ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            suppressCellFocus={true}
            defaultColDef={{ flex: 1 }}
            onRowClicked={handleRowClick} // Correctly map to the handler
          />
        </div>
      )}

      {!isCollapsed && (
        <div className="menu-panel">
          <button onClick={() => onResize(50)}>
            <i className="fa-solid fa-square-caret-down"></i>
          </button>
          <button onClick={() => onResize(-50)}>
            <i className="fa-solid fa-square-caret-up"></i>
          </button>
          <button onClick={toggleLayout}>
            {isSingleColumn ? (
              <i className="fa-solid fa-table-columns"></i>
            ) : (
              <i className="fa-solid fa-window-maximize"></i>
            )}
          </button>
          {footerButton && <div style={{ marginLeft: 'auto' }}>{footerButton}</div>}
        </div>
      )}
    </div>
  );
};

export default GridPanel;
