// src/Panels/JobsAvailable.js

import React, { useEffect, useState } from 'react';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';

const JobsAvailable = ({ isSingleColumn, onRowSelectAction }) => {
  const [availableJobs, setAvailableJobs] = useState([]);
  const [panelHeight, setPanelHeight] = useState(300);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const columnDefsAvailableJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.9 },
    { headerName: "TRADE", field: "tradeName", flex: 1.7 },
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    // { headerName: "DATE POSTED", field: "postedAt", flex: 1.4,
    //   valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const loadAvailableJobs = async () => {
      const options = { status: 2 };
      const jobs = await fetchJobs(options);
      setAvailableJobs(jobs);
    };

    loadAvailableJobs();
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  const handleResize = (change) => setPanelHeight(panelHeight + change);

  const handleJobRowClick = (event) => {
    const jobData = event.data;
    const tabModes = {
      Quote: 'readOnly',
      Detail: 'readWrite',
      Comments: 'readWrite',
      Appointments: 'readWrite'
    };

    onRowSelectAction(jobData, tabModes); // Notify parent about the selected job
  };

  return (
    <GridPanel
      title="Jobs Available - 2"
      columnDefs={columnDefsAvailableJobs}
      rowData={availableJobs}
      height={panelHeight}
      onResize={handleResize}
      isCollapsed={isCollapsed}
      toggleCollapse={toggleCollapse}
      onRowClick={handleJobRowClick} // Pass the row click handler
      isSingleColumn={isSingleColumn}
    />
  );
};

export default JobsAvailable;
