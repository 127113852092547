import React from 'react';

/**
 * LandlordDashboardHeader Component
 * Displays the header for the landlord dashboard.
 */
const LandlordDashboardHeader = () => {
  return (
    <></>
    // <div className="dashboard-header">
    //   <h1>Landlord Dashboard</h1>
    // </div>
  );
};

export default LandlordDashboardHeader;
