// src/components/UI/ThumbnailDisplay.js

import React from 'react';

const ThumbnailDisplay = ({ images, onDelete }) => {
  return (
    <div className="thumbnail-display">
      {images.map((image, index) => (
        <div key={index} className="thumbnail-container">
          <img src={image} alt="thumbnail" className="thumbnail" />
          <button className="delete-button" onClick={() => onDelete(index)}>X</button>
        </div>
      ))}
    </div>
  );
};

export default ThumbnailDisplay;
