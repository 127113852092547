import React, { useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/AgentManageProperties.css';
import GridPanel from '../components/GridPanel';
import { fetchJobs } from '../services/jobService';
import { formatTimestamp, formatDateOnly } from '../Utilities/dateUtils';
import { AgentsPropertiesSubmitted } from '../services/dbService';


const ManageProperties = ({ agentData }) => {
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [isSingleColumn, setIsSingleColumn] = useState(false);
  const [panelHeight, setPanelHeight] = useState(300);
  const [collapsedPanels, setCollapsedPanels] = useState({ panel1: false, panel2: false });
  const [properties, setProperties] = useState([]);
  const [agentEmail, setAgentEmail] = useState('');

  useEffect(() => {
    if (agentData?.email) {
      setAgentEmail(agentData.email);
    }
  }, [agentData]);

  const columnDefs = [
    { headerName: "PROPERTY ID", field: "id", flex: 0.6 },
    { headerName: "ADDRESS", field: "address", flex: 1.5 },
    { headerName: "POSTCODE", field: "postcode", flex: 0.5 },
    { headerName: "INVITED", field: "invitationDate", flex: 0.5,
      valueFormatter: (params) => formatDateOnly(params.value) },
    { headerName: "ACCEPTED", field: "inviteAcceptedAt", flex: 0.5,
      valueFormatter: (params) => formatDateOnly(params.value) }
    ];

  const columnDefsApprovedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 1 },
    {
      headerName: "ADDRESS",
      valueGetter: (params) => `${params.data.propertyDetails?.address || ''}, ${params.data.propertyDetails?.postcode || ''}`,
      flex: 4,
    },
    { headerName: "TRADE", field: "tradeName", flex: 2 },
    // { headerName: "CONTRACTOR", field: "contractorName", flex: 1 },
  ];  

  const columnDefsCompletedJobs = [
    { headerName: "JOB ID", field: "jobId", flex: 0.7 },
    { headerName: "ADDRESS", field: "", flex: 1.0 }, 
    { headerName: "DESCRIPTION", field: "jobDetail", flex: 3 },
    { headerName: "CONTRACTOR NAME", field: "contractorName", flex: 1.2 },
    { headerName: "COMPLETED DATE", field: "completedDate", flex: 0.7,
      valueFormatter: (params) => formatTimestamp(params.value) }
  ];

  useEffect(() => {
    const fetchPropertiesSubmittedForAgent = async () => {
      try {
        const agentProperties = await AgentsPropertiesSubmitted(agentEmail);
        setProperties(agentProperties);
      } catch (error) {
        console.error('Error fetching agent properties:', error);
      }
    };
  
    if (agentEmail) {
      fetchPropertiesSubmittedForAgent();
    } 
  }, [agentEmail]);

  useEffect(() => {
    const loadApprovedJobs = async () => {
      const options = { 
        status: 2, 
        agentId: agentEmail, 
      };
      const jobs = await fetchJobs(options);
      setApprovedJobs(jobs);
    };

    loadApprovedJobs();
  }, [agentEmail]);

  useEffect(() => {
    const loadCompletedJobs = async () => {
      const options = { status: 9 }; // Fetch jobs with status == 9 for completed jobs
      const jobs = await fetchJobs(options);
      setCompletedJobs(jobs);
    };

    loadCompletedJobs();
  }, []);

  const handleResize = (change) => {
    setPanelHeight(panelHeight + change);
  };

  const toggleLayout = () => {
    if (!isSingleColumn) {
      setCollapsedPanels({ panel1: false, panel2: false });
    }
    setIsSingleColumn(!isSingleColumn);
  };

  const toggleCollapse = (panel) => {
    if (isSingleColumn) {
      setCollapsedPanels((prev) => ({
        ...prev,
        [panel]: !prev[panel],
      }));
    } else {
      const newCollapseState = !collapsedPanels[panel];
      setCollapsedPanels({
        panel1: newCollapseState,
        panel2: newCollapseState,
      });
    }
  };

  return (
    <div className="manage-properties-container">
      <div className="row">
        <div className="grid-section">
          <GridPanel
            key={columnDefs.length}
            title="Properties Submitted"
            rowData={properties}
            columnDefs={columnDefs}
            height={panelHeight}
            onResize={handleResize}
            isSingleColumn={isSingleColumn}
            toggleLayout={toggleLayout}
            isCollapsed={collapsedPanels.panel1}
            toggleCollapse={() => toggleCollapse('panel1')}
          />
        </div>

        <div className="grid-section">
          <GridPanel
            key={columnDefs.length}
            title="Jobs Accepted"
            rowData={approvedJobs}
            columnDefs={columnDefsApprovedJobs}
            height={panelHeight}
            onResize={handleResize}
            isSingleColumn={isSingleColumn}
            toggleLayout={toggleLayout}
            isCollapsed={collapsedPanels.panel1}
            toggleCollapse={() => toggleCollapse('panel1')}
          />
        </div>

      </div>
      <div className="row full-width">
        <div className="grid-section">

          <GridPanel
            key={columnDefs.length}
            title="Jobs Completed & Closed"
            rowData={completedJobs}
            columnDefs={columnDefsCompletedJobs}
            height={panelHeight}
            onResize={handleResize}
            isSingleColumn={isSingleColumn}
            toggleLayout={toggleLayout}
            isCollapsed={collapsedPanels.panel1}
            toggleCollapse={() => toggleCollapse('panel1')}
          />

        </div>
      </div>
    </div>
  );
};

export default ManageProperties;
