import React, { useEffect } from "react";

const DateSelect = ({ dateValue = { day: '', month: '', year: '' }, setDateValue, hasDefaultDate = false }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Months are 0-indexed, so we add 1
  const currentDay = new Date().getDate();

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);

  useEffect(() => {
    if (hasDefaultDate) {
      // Default to today's date if hasDefaultDate is true
      setDateValue({
        day: currentDay,
        month: currentMonth,
        year: currentYear,
      });
    }
  }, [setDateValue, hasDefaultDate, currentDay, currentMonth, currentYear]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    // Update the dateValue object with the selected values
    const updatedDate = {
      ...dateValue,
      [name]: value,
    };

    setDateValue(updatedDate);
  };

  return (
    <div className="date-selects form-inline">
      <select name="day" value={dateValue.day} onChange={handleDateChange} required>
        <option value="">Day</option>
        {days.map((day) => (
          <option key={day} value={day}>{day}</option>
        ))}
      </select>
      <select name="month" value={dateValue.month} onChange={handleDateChange} required>
        <option value="">Month</option>
        {months.map((month, index) => (
          <option key={month} value={index + 1}>{month}</option>
        ))}
      </select>
      <select name="year" value={dateValue.year} onChange={handleDateChange} required>
        <option value="">Year</option>
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
    </div>
  );
};

export default DateSelect;

// Example Usage:

// To use the component without a default date:
// <DateSelect setDateValue={setCompletionDate} hasDefaultDate={false} />

// To use the component with today's date as the default:
// In the calling code, define the date state:
// const [completionDate, setCompletionDate] = useState({ day: '', month: '', year: '' });

// Use the DateSelect component:
// <DateSelect dateValue={completionDate} setDateValue={setCompletionDate} hasDefaultDate={true} />
