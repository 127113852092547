import React, { useState } from 'react';
import { sendSignInLinkToEmail } from 'firebase/auth'; // Import Firebase auth methods
import { auth, db } from '../firebase'; // Firebase auth and Firestore instances
import { setDoc, doc } from 'firebase/firestore'; // Import setDoc and doc
import '../styles/FormsDefault.css'; 
import '../styles/SettingsAddUser.css';


const SettingsAddUser = () => {
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteError, setInviteError] = useState(null);
  const [inviteSuccess, setInviteSuccess] = useState(null);
  const [role, setRole] = useState(''); // Initialize with an empty string
  const [isPlatinum, setIsPlatinum] = useState(false); // State for Platinum checkbox

  // Handle invite submission
  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    setInviteError(null);
    setInviteSuccess(null);

    if (!inviteEmail || !role) {
      setInviteError('Email and role are required');
      return;
    }

    try {
      const inviter = auth.currentUser;
      const invitedByName = inviter?.displayName || 'Unknown';
      const invitedById = inviter?.uid || 'Unknown';

      // Set subscription type based on Platinum checkbox and role
      const subscriptionType = role === 'Contractor' && isPlatinum ? 0 : null;

      // Step 1: Add invited user to Firestore with a complete but blank record
      await setDoc(doc(db, 'users', inviteEmail), {
        email: inviteEmail,
        role,
        isProfileComplete: false,
        enabled: true,
        firstName: "",
        lastName: "",
        mobile: "",
        landline: "",
        companyName: "",
        companyAddress: "",
        companyPostcode: "",
        companyMainContactName: "",
        officePhoneNumber: "",
        paymentBankName: "",
        paymentAccountNumber: "",
        paymentSortCode: "",
        invitedAt: new Date(),
        lastLoggedIn: null,
        invitedByName,
        invitedById,
        subscriptionType,
      });

      // Step 2: Send the email invitation using Firebase Authentication
      const actionCodeSettings = {
        url: `${process.env.REACT_APP_SITE_URL}/complete-profile?email=${encodeURIComponent(inviteEmail)}`,
        handleCodeInApp: true,
      };
    

      await sendSignInLinkToEmail(auth, inviteEmail, actionCodeSettings);

      // Step 3: Provide feedback and reset the form
      setInviteSuccess(`Invitation sent to ${inviteEmail} with role ${role}`);
      setInviteEmail('');
      setRole('');
      setIsPlatinum(false); // Reset Platinum checkbox
    } catch (error) {
      console.error('Failed to send invitation:', error);
      setInviteError('Failed to send invitation. Please try again.');
    }
  };

  return (
    <div className='add-user-body'>
      <div className="add-user-form-container">
        <h2>Add User</h2>
        <form onSubmit={handleInviteSubmit}>
          <label className="add-user-label">Role:</label>
          <select className="add-user-select" value={role} onChange={(e) => setRole(e.target.value)} required>
            <option value="" disabled>Select</option>
            <option value="Owner">Owner</option>
            <option value="Admin">Admin</option>
            <option value="Agent">Agent</option>
            <option value="Contractor">Contractor</option>
          </select>

          {role === 'Contractor' && (
            <div className="add-user-checkbox-group">
              <input
                type="checkbox"
                id="platinum-checkbox"
                checked={isPlatinum}
                onChange={(e) => setIsPlatinum(e.target.checked)}
              />
              <label htmlFor="platinum-checkbox" className="add-user-label">Platinum user</label>
            </div>
          )}

          <label className="add-user-label">Email address to invite:</label>
          <input
            type="email"
            className="add-user-input"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            required
          />

          {inviteError && <p className="add-user-error-message">{inviteError}</p>}
          {inviteSuccess && <p className="add-user-success-message">{inviteSuccess}</p>}

          <button type="submit" className="add-user-button">Send Invite</button>
        </form>
      </div>
    </div>
  );
};

export default SettingsAddUser;
