import React from 'react';
import JobDetailForm from './JobDetailForm';

const JobTabsDetail = ({jobId}) => (
  <div className="job-tabs-detail">
    <JobDetailForm jobId={jobId}/>
  </div>
);

export default JobTabsDetail;
