import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../context/AuthContext'; // Adjust the path as needed
import JobTabsDetail from './JobTabsDetail';
import Comments from '../Comments/Comments';
import Calendar from '../Calendar/Calendar';
import QuoteForm from '../QuoteForm/QuoteForm';
import { fetchPropertyById } from '../services/dbService';
import '../styles/JobTabs.css';

const JobTabs = ({ jobData, onReturnToDashboard, tab, isAdmin, tabModes }) => {  
  const { user } = useAuthContext(); 
  const isLandlord = user?.role === 'Landlord';
  // const isAdmin = user?.role === 'Admin';
  const [activeTab, setActiveTab] = useState(tab || 'Detail');
  const [propertyDetails, setPropertyDetails] = useState('');

  const handleTabClick = (tab) => setActiveTab(tab);

  useEffect(() => {
    const loadProperty = async () => {
      try {
        const propertyData = await fetchPropertyById(jobData.propertyId);
        if (propertyData) {
          setPropertyDetails(propertyData);
        }
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    loadProperty();
  }, [jobData.propertyId]);

  const visibleTabs = Object.keys(tabModes).filter(key => tabModes[key] !== 'hide');
  debugger;
  const tabs = [
    { name: 'Quote', component: <QuoteForm jobData={jobData} onClose={onReturnToDashboard} readOnly={tabModes?.Quote === 'readOnly'} landlord /> },
    { name: 'Detail', component: <JobTabsDetail jobId={jobData.jobId} readOnly={tabModes?.Detail === 'readOnly'} /> },
    { name: 'Comments', component: <Comments jobData={jobData} propertyDetails={propertyDetails} readOnly={tabModes?.Comments === 'readOnly'} /> },
    { name: 'Appointments', component: <Calendar jobId={jobData.jobId} readOnly={tabModes?.Appointments === 'readOnly'} /> },
  ].filter(tab => visibleTabs.includes(tab.name));

  return (

    <div className="job-tabs-container">
      <div className="property-details-panel">
        <div className="property-details-row">
          <div className="property-details-item">
            <span className="property-details-title">Address</span>
            <span className="property-details-value">{propertyDetails.address}, {propertyDetails.postcode}</span>
          </div>
          <div className="property-details-item">
            <span className="property-details-title">Agency</span>
            <span className="property-details-value">{propertyDetails.agency}</span>
          </div>
          <div className="property-details-item">
            <span className="property-details-title">Landlord</span>
            <span className="property-details-value">{propertyDetails.user?.firstName} {propertyDetails.user?.lastName}</span>
          </div>
          <div className="property-details-item">
            <span className="property-details-title">Mobile</span>
            <span className="property-details-value">{propertyDetails.user?.mobileNumber}</span>
          </div>
        </div>
      </div>

      {/* Tab Buttons */}
      <div className="job-tabs-button-group">
        {tabs.map(({ name }) => <button key={name} onClick={() => handleTabClick(name)} className={`job-tabs-button ${activeTab === name ? 'job-tabs-button-active' : ''}`}>{name}</button>)}
        
        
        {/* {isAdmin && ( */}
        {(isAdmin || isLandlord) && (
          <button className="negative-button" onClick={onReturnToDashboard} style={{ marginLeft: 'auto' }}>
            Return to Dashboard
          </button>
        )}

      </div>

      {/* Tab Content */}
      <div className="job-tabs-content">
        {tabs.find(({ name }) => name === activeTab)?.component || <p>Invalid tab selected</p>}
      </div>
    </div>
  );
};

export default JobTabs;
