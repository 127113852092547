import React, { useState, useEffect } from "react";
import { fetchAllUsers } from '../services/dbService';
import { formatTimestamp } from '../Utilities/dateUtils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles/SettingsUsers.css';

const SettingsUsers = (props) => {
  const [rowData, setRowData] = useState([]);
  const [paginationPageSize] = useState(10);

  useEffect(() => {
    const loadAllUsers = async () => {
      try {
        const users = await fetchAllUsers();
        setRowData(users);
      } catch (error) {
        console.error('SettingsUser: Error fetching Users: ', error);
      }

    }
    loadAllUsers()
  }, []);

const columnDefs = [
    { field: "firstName", headerName: "First Name", flex: 0.6 },
    { field: "lastName", headerName: "Last Name", flex: 0.6 },
    { field: "companyName", headerName: "Company", flex: 0.6 },
    { field: "role", headerName: "User Type", flex: 0.6 },
    { field: "mobile", headerName: "Mobile", flex: 0.6 },
    { field: "officePhoneNumber", headerName: "Landline", flex: 0.6 },
    { field: "isProfileComplete", headerName: "Profile Complete", flex: 0.6},
    { headerName: "Last Logged In", field: "lastLoggedIn", flex: 0.6 ,
      valueFormatter: (params) => formatTimestamp(params.value) },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: '50vh', width: '100%' }}>

      <div className="users-heading">
        <h2>Users</h2>
      </div>

      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={paginationPageSize}
      />
    </div>
  );
} 

export default SettingsUsers;
