import React, { useState, useEffect } from 'react';
import { fetchAgentData } from '../services/dbService';
import InviteLandlordForm from './AgentInviteLandlordForm';
import ManageProperties from './AgentManageProperties';
import '../styles/AgentDashboards.css';
import { auth } from '../firebase';

const AgentDashboard = () => {
  const [activePanel, setActivePanel] = useState(null); // Tracks the active panel: 'inviteLandlord' or 'manageProperties'
  const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark-mode') === 'true');
  const [agentData, setAgentData] = useState(null);
  const [error, setError] = useState(null);
  
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle('light-mode', !newMode);
      localStorage.setItem('dark-mode', newMode ? 'true' : 'false');
      return newMode;
    });
  };

  useEffect(() => {
    const loadAgentData = async () => {
      try {
        const userEmail = auth.currentUser?.email;
        if (!userEmail) {
          throw new Error('User is not logged in.');
        }

        const data = await fetchAgentData(userEmail);
        setAgentData(data);
      } catch (err) {
        console.error('Error fetching agent data:', err.message);
        setError(err.message);
      }
    };

    loadAgentData();
  }, []);
    
  useEffect(() => {
    const savedMode = localStorage.getItem('dark-mode') === 'true';
    setIsDarkMode(savedMode);
    document.documentElement.classList.toggle('light-mode', !savedMode);
  }, []);
  
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!agentData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="agent-dashboard">
      <div className="dashboard-header">
        <h1>Agent Dashboard</h1>
        <button className="mode-toggle" onClick={toggleDarkMode}>
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>
  

    <div className='agent-dashboard-welcome-panel'>
    <p>
      To add any potential future home owner, please click the "invite landlord" button. This will allow the future homeowner to communicate within the hub to both the admin, and contractors completing any works at the property. You, as the agent will have complete visibility of all communications and can check on any works whenever you feel, or simply allow CMH to do its job and allow the commission to come in once works are completed
    </p>
    <p>
      Please check on our progress tabs to see the status of all and any works either agreed or completed within the hub, whether a job had been quoted for, or is close to completion, all is visible within our simplistic yet streamlined Hub
    </p>

    </div>


      {/* Invite Landlord Panel */}
      {activePanel === 'inviteLandlord' && (
        <div className="dashboard-panel">
          <div className="panel-header">
            <h2>Invite Landlord</h2>
            <button
              className="positive-button"
              onClick={() => setActivePanel('manageProperties')}
            >
              Return to Dashboard
            </button>
          </div>
          <p>Send an invitation to a landlord to join the platform.</p>
          <InviteLandlordForm
            agentData={agentData}
            onSubmit={() => setActivePanel(null)}
            onClose={() => setActivePanel(null)}
          />
        </div>
      )}
  
      {/* Manage Properties Panel */}
      {activePanel === 'manageProperties' && (
        <div className="dashboard-panel">
          <div className="panel-header">
            <h2>Manage Properties</h2>
            <button
              className="positive-button"
              onClick={() => setActivePanel('inviteLandlord')}
            >
              Invite Landlord
            </button>
          </div>
          <p>View and manage the properties you are responsible for.</p>
          <ManageProperties agentData={agentData} />
        </div>
      )}
  
      {/* Fallback Buttons if No Panel is Active */}
      {activePanel === null && (
        <div className="dashboard-panel fallback-buttons">
          <button
            className="dashboard-button"
            onClick={() => setActivePanel('inviteLandlord')}
          >
            Invite Landlord
          </button>
          <button
            className="dashboard-button"
            onClick={() => setActivePanel('manageProperties')}
          >
            View Properties
          </button>
        </div>
      )}

    </div>
  );
  
};

export default AgentDashboard;
