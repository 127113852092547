import React, { useState } from 'react';
import JobTabs from '../Job/JobTabs';
import JobsToPrice from '../Panels/JobsToPrice';
import QuotedWaitingOnLLAcceptance from '../Panels/QuotedWaitingOnLLAcceptance'
import JobApprovedAwaitingContractor from '../Panels/JobApprovedAwaitingContractor'
import JobsAvailable from '../Panels/JobsAvailable';
import JobsAccepted from '../Panels/JobsAccepted';
import StartDateOffered from '../Panels/StartDateOffered';
import DateAcceptedAwaitingStartDate from '../Panels/DateAcceptedAwaitingStartDate';
import JobInProgress from '../Panels/JobInProgress';
import JobCompletedInvoiceSent from '../Panels/JobCompletedInvoiceSent';
import JobCompletedClosed from '../Panels/JobCompletedClosed';
import JobCancelled from '../Panels/JobCancelled';


const Dashboard = () => {

  const [selectedJob, setSelectedJob] = useState(null); // Track the selected job
  const [tabModes, setTabModes] = useState({});
  
  const handleRowSelected = (jobData, tabModes) => {
    setSelectedJob(jobData);
    setTabModes(tabModes);
  };

  const handleBackToDashboard = () => {
    setSelectedJob(null); // Clear the selected job and return to the dashboard
  };

  return (
    <div>
      {selectedJob ? (
        // Show Job.js when a job is selected
        <JobTabs jobData={selectedJob} onReturnToDashboard={handleBackToDashboard} tab={'Quote'} isAdmin={true} tabModes={tabModes} />
      ) : (
        // Default dashboard content
        <>
          <div className="dashboard-container two-columns">
              <JobsToPrice isSingleColumn={false} onRowSelectAction={handleRowSelected} />
              <QuotedWaitingOnLLAcceptance isSingleColumn={false} onRowSelectAction={handleRowSelected} />
          </div>

          <div className="dashboard-container">
            <JobApprovedAwaitingContractor isSingleColumn={true} onRowSelectAction={handleRowSelected} />
          </div>

          <div className="dashboard-container two-columns">
            <JobsAvailable isSingleColumn={false} onRowSelectAction={handleRowSelected } />
            <JobsAccepted isSingleColumn={false} onRowSelectAction={handleRowSelected } />
          </div>

          <div className="dashboard-container two-columns">
            <StartDateOffered isSingleColumn={false} onRowSelectAction={handleRowSelected } />
            <DateAcceptedAwaitingStartDate isSingleColumn={false} onRowSelectAction={handleRowSelected } />
          </div>

          <div className="dashboard-container two-columns">
            <JobInProgress isSingleColumn={false} onRowSelectAction={handleRowSelected } />
            <JobCompletedInvoiceSent isSingleColumn={false} onRowSelectAction={handleRowSelected } />
          </div>

          <div className="dashboard-container two-columns">
            <JobCompletedClosed isSingleColumn={false} onRowSelectAction={handleRowSelected } />
            <JobCancelled isSingleColumn={false} onRowSelectAction={handleRowSelected } />
          </div>

        </>
      )}


    </div>
  );
};

export default Dashboard;
