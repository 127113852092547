import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const fetchVisibilitySettings = async (gridName) => {
    const docRef = doc(db, 'dataVisibility', gridName);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().columnVisibility : {};
};

export const updateVisibilitySetting = async (gridName, column, role, isVisible) => {
    const docRef = doc(db, 'dataVisibility', gridName);
    await updateDoc(docRef, {
        [`columnVisibility.${column}.${role}`]: isVisible
    });
};

export const getVisibleColumnsForRole = async (gridName, role) => {
  const visibilitySettings = await fetchVisibilitySettings(gridName);
  // Filter columns based on role visibility
  return Object.keys(visibilitySettings).filter(column => visibilitySettings[column][role]);
};
