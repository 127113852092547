import React from 'react';
import LandlordManageProperties from '../Landlord/LandlordManageProperties';

/**
 * LandlordManagePropertiesPanel Component
 * Displays the "Manage Properties" section of the landlord dashboard.
 */
const LandlordManagePropertiesPanel = ({ property, managePropertiesRef, onRowSelectAction }) => {
  return (
    <>
      {property?.propertyId ? (
        <LandlordManageProperties
          onRowSelectAction={onRowSelectAction} // Pass row click handler
          propertyId={property.propertyId}
          ref={managePropertiesRef} // Pass ref to access refreshGrid
        />
      ) : (
        <p>Loading property details...</p>
      )}
    </>
  );
};

export default LandlordManagePropertiesPanel;
