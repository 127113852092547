import { useState, useEffect } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from 'firebase/auth';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const auth = getAuth();

    // Handle email link sign-in
    const handleEmailSignIn = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const emailForSignIn = window.localStorage.getItem('emailForSignIn');
        if (!emailForSignIn) {
          alert('The sign-in process could not be completed. Please click the email link again.');
          window.location.href = '/login-user';
          return;
        }

        try {
          const result = await signInWithEmailLink(auth, emailForSignIn, window.location.href);
          setIsAuthenticated(true);
          setUserEmail(result.user.email);
        } catch (error) {
          alert('Error signing in with email link: ' + error.message);
        } finally {
          window.localStorage.removeItem('emailForSignIn');
        }
      }
    };

    handleEmailSignIn();

    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserEmail(user.email);
      } else {
        setIsAuthenticated(false);
        setUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    const auth = getAuth();
    auth.signOut()
      .then(() => {
        setIsAuthenticated(false);
        setUserEmail('');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return { isAuthenticated, userEmail, logout };
};

export default useAuth;
