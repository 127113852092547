import React from "react";
import JobTopPanel from "./JobTopPanel";
import JobTabs from "./JobTabs";
import '../styles/Job.css';

const Job = () => {

  return (
    <div>
      <JobTopPanel 
        jobId="M-5101-1" 
        landlord="David Roberts" 
        agent="Foxtons (Canary Wharf) - Greg Ashton - 07957 010321" 
        status="Not assigned" 
      />
      <JobTabs />
    </div>
  );

}

export default Job;