import React, { useRef, useEffect } from 'react';
import { ReactComponent as ReactSprite } from "../../assets/sprite.svg";
import { NavLink } from 'react-router-dom';
import '../../styles/SideNav.css';

  const SideNav = (props) => {

    const collapseBtnRef = useRef(null); // For mobile menu toggle
    const menuLinksRef = useRef([]); // For handling menu links hover (add multiple refs)
    const switchLabelTextRef = useRef(null); // For text in the light/dark mode switch
    const body = document.body; // Reference to body element
    const html = document.documentElement; // Reference to the html element
    const { isCollapsed, setIsCollapsed, isDarkMode, toggleDarkMode, isContractor } = props;

    // Toggle Mobile Menu
    const handleMobileMenuToggle = () => {
      document.body.classList.toggle("mob-menu-opened");
    };

    // Show Tooltip on Menu Link Hover
    const handleLinkHover = (event) => {
      if (isCollapsed) {
        console.log('Show Tooltip');
        const tooltip = event.currentTarget.querySelector("span").textContent;
        event.currentTarget.setAttribute("title", tooltip);
      } else {
        event.currentTarget.removeAttribute("title");
      }
    };

    useEffect(() => {
      
      // Attach event listeners to menu links
      menuLinksRef.current.forEach((link) => {
        link.addEventListener("mouseenter", handleLinkHover);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body.classList, html.classList]);
      
  return (
    
    <header className={`side-panel ${isCollapsed ? 'collapsed' : ''}`}>
      <nav>
        <button
          className="toggle-mob-menu"
          aria-expanded="false"
          aria-label="open menu"
          onClick={handleMobileMenuToggle}
        >
          <svg width="20" height="20" aria-hidden="true">
            <use xlinkHref="#down"></use>
          </svg>
        </button>

        <ul className="side-menu">
          <ReactSprite />

          {isContractor ? (
            <li>
            <NavLink to="/contractor-dashboard" onMouseEnter={handleLinkHover} ref={(el) => (menuLinksRef.current[0] = el)}>
              <svg>
                <use xlinkHref="#pages"></use>
              </svg>
              <span>Dashboard</span>
            </NavLink>
            </li>

          ):(
            <li>
            <NavLink to="/dashboard" onMouseEnter={handleLinkHover} ref={(el) => (menuLinksRef.current[0] = el)}>
              <svg>
                <use xlinkHref="#pages"></use>
              </svg>
              <span>Dashboard</span>
            </NavLink>
            </li>
            )}

          <li>
            <NavLink to="/job" onMouseEnter={handleLinkHover} ref={(el) => (menuLinksRef.current[0] = el)}>
            <svg>
                <use xlinkHref="#users"></use>
              </svg>
              <span>Notifications</span>
            </NavLink>
          </li>

        {!isContractor && (
          <li>
            <a href="#0" ref={(el) => (menuLinksRef.current[2] = el)}>
            <svg>
              <use xlinkHref="#trends"></use>
            </svg>
            <span>Client Profile</span>
            </a>
          </li>
        )}
          <li>
            <a href="#0" ref={(el) => (menuLinksRef.current[3] = el)}>
              <svg>
                <use xlinkHref="#collection"></use>
              </svg>
              <span>Calendar</span>
            </a>
          </li>

          <li>
            <a href="#0" ref={(el) => (menuLinksRef.current[4] = el)}>
              <svg>
                <use xlinkHref="#comments"></use>
              </svg>
              <span>Software T & C</span>
            </a>
          </li>

          <li>

        {!isContractor && (
            <NavLink to="/settings" ref={(el) => (menuLinksRef.current[6] = el)}>
              <svg>
                <use xlinkHref="#options"></use>
              </svg>
              <span>Settings</span>
            </NavLink>
        )}


            {/* HIDE SHOW SIDE NAVIDATION */}
            <button
              ref={collapseBtnRef}
              className="collapse-btn"
              aria-expanded={!isCollapsed}
              aria-label={isCollapsed ? "expand menu" : "collapse menu"}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <svg aria-hidden="true">
                <use xlinkHref="#collapse"></use>
              </svg>
              <span>Collapse</span>
            </button>

            {/* DARK MODE TOGGLE */}
            <button
              className="collapse-btn"
              aria-label={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
              onClick={toggleDarkMode}
            >
              <svg aria-hidden="true">
                <use xlinkHref="#appearance"></use>
              </svg>
              <span ref={switchLabelTextRef}>
                {isDarkMode ? "Light Mode" : "Dark Mode"}
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default SideNav;