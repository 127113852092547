import { db } from '../firebase'; // Import Firebase instance
import { collection, addDoc } from 'firebase/firestore';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

// Function to add a user to the Firestore users collection
export const addUserToFirestore = async (userData) => {
  try {
    await addDoc(collection(db, 'users'), {
      ...userData,
      dateCreated: new Date().toISOString(),
      isProfileComplete: false,
      enabled: true
    });
  } catch (error) {
    throw new Error('Failed to add user to Firestore');
  }
};

// Function to send a sign-in link to the user's email address
export const sendSignInLink = async (email) => {
  const auth = getAuth();
  const actionCodeSettings = {
    url: `${process.env.REACT_APP_SITE_URL}/complete-profile?email=${encodeURIComponent(email)}`,
    handleCodeInApp: true,
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  } catch (error) {
    throw new Error('Error sending email: ' + error.message);
  }
};
