import React, { useEffect, useState } from 'react';
import { fetchVisibilitySettings, updateVisibilitySetting } from '../services/dataVisibilityService';
import FlashMessage from '../components/UI/FlashMessage';
import '../styles/FlashMessage.css'; // Import the FlashMessage CSS
import '../styles/DataVisibilitySettings.css';

const roles = ['admin', 'agent', 'landlord'];

const SettingsDataVisibility = () => {
    const [selectedGrid, setSelectedGrid] = useState('PropertiesSubmitted');
    const [visibilitySettings, setVisibilitySettings] = useState({});
    const [flashMessage, setFlashMessage] = useState(null);

    useEffect(() => {
        const loadSettings = async () => {
            const settings = await fetchVisibilitySettings(selectedGrid);
            setVisibilitySettings(settings);
        };
        loadSettings();
    }, [selectedGrid]);


    useEffect(() => {
      const loadSettings = async () => {
          const settings = await fetchVisibilitySettings(selectedGrid);
          setVisibilitySettings(settings);
      };
      loadSettings();
    }, [selectedGrid]);


    // Handle checkbox toggle and Firestore update
    const handleToggle = async (column, role) => {
        const updatedSettings = {
            ...visibilitySettings,
            [column]: {
                ...visibilitySettings[column],
                [role]: !visibilitySettings[column][role]
            }
        };
        setVisibilitySettings(updatedSettings);
        await updateVisibilitySetting(selectedGrid, column, role, updatedSettings[column][role]);

        // Trigger flash message on successful update
        setFlashMessage(`Visibility for ${column} (${role}) updated successfully!`);
      };

    return (
        <div className="data-visibility-settings">
            <div className="dropdown-container">

              <div className='flex-group'>
                <label>Select Grid:</label>

                {flashMessage && (
                  <FlashMessage
                    message={flashMessage}
                    onClose={() => setFlashMessage(null)}
                    color="success"
                  />
                )}
              </div>

                <select onChange={(e) => setSelectedGrid(e.target.value)}>
                    <option value="PropertiesSubmitted">Properties Submitted</option>
                </select>

            </div>
            
            <div className="visibility-settings">
                <div className="header-row">
                    <span>Column</span>
                    {roles.map((role) => (
                        <span key={role} className="role-header">{role}</span>
                    ))}
                </div>

                {Object.keys(visibilitySettings).map((column) => (
                    <div key={column} className="column-setting">
                        <span>{column}</span>
                        {roles.map((role) => (
                            <label key={role} className="role-checkbox">
                                <input
                                    type="checkbox"
                                    checked={visibilitySettings[column]?.[role] || false}
                                    onChange={() => handleToggle(column, role)}
                                />
                            </label>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SettingsDataVisibility;
